/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useContext, useState } from 'react';
import { MyProfileIcon, MySmallLogoIcon } from '@components/Svg';
import LoadingComponent from '@components/LoadingComponent';
import Share from '@public/share.svg';
import Copy from '@public/copy.svg';
import Download from '@public/download.svg';
import Upload from '@assets/Upload.svg';
import { v4 as uuidv4 } from 'uuid';
import Profile from '@assets/user-circle.svg';
import axios from 'axios';
import Music from '@assets/music.svg';
import Close from '@assets/close.svg';
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material';
import Markdown from 'react-markdown';
import { toast } from 'react-toastify';
import Skeleton from '@src/pages/dashboard/components/skeleton';
import { RiArrowLeftSLine } from 'react-icons/ri';
import { useNavigate } from 'react-router';
import axiosRetry from 'axios-retry';
import logo from '@assets/essay/GMind_Icon_Orange_compressed.png';
import darkLogo from '@assets/essay/GMind_Icon_White_compressed.png';
import { ThemeContext } from '@src/theme_provider/theme';

interface promptOptionsType {
    name: string;
    icon: string;
    onClick: (e: string) => void;
}
interface UploadBoxProps {
    onFileUpload: (file: File) => void;
}

axiosRetry(axios, {
    retries: 2, // Number of retries
    retryCondition: (error) => {
        // Retry on network errors
        return axiosRetry.isNetworkOrIdempotentRequestError(error);
    },
});

const UploadBox = ({ isMobile, back }: { isMobile: boolean; back?: () => void }) => {
    const [transcriptLoading, setTranscriptLoading] = useState(false);
    const [transcript, setTranscript] = useState('');
    const [transcribed, setTranscribed] = useState<number>(0);
    const [uploading, setUploading] = useState<boolean>(false);
    const [uploadedFile, setUploadedFile] = useState<File | null>(null);
    const [openAlert, setOpenAlert] = useState(false);
    const [audioBlobUrl, setAudioBlobUrl] = useState('');
    const navigate = useNavigate();
    const {savedTheme}= useContext(ThemeContext)

    const promptOptionsData: Array<promptOptionsType> = [
        {
            name: 'Share',
            icon: Share,
            onClick(e) {},
        },
        {
            name: 'Copy',
            icon: Copy,
            onClick(e) {
                copyToClipboard();
            },
        },
        {
            name: 'Download',
            icon: Download,
            onClick(e) {},
        },
    ];

    const handleTranscribed = () => {
        if (!uploadedFile) {
            setOpenAlert(true);
            return;
        }
        setTranscribed(1);
        getInput();
    };
    const onFileUpload = (file: File) => {};

    const handleUploadButtonClick = () => {
        const audioInput = document.createElement('input');
        audioInput.type = 'file';
        audioInput.accept = 'audio/*'; // Allow only audio files
        audioInput.multiple = false; // Allow only single file selection
        audioInput.addEventListener('change', (event: Event) => {
            const target = event.target as HTMLInputElement;
            handleFileSelect({ target } as React.ChangeEvent<HTMLInputElement>);
        });
        audioInput.click();
    };
    const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            setUploadedFile(file);
            onFileUpload(file);
            setUploading(true);
            setTimeout(() => {
                setUploading(false);
            }, 2000);
        }
    };

    const removeUploadFile = () => {
        setUploadedFile(null);
    };
    const handleCloseAlert = () => {
        setOpenAlert(false);
    };

    const getInput = () => {
        const file: File = uploadedFile!;
        const reader = new FileReader();

        const blob = file.slice(0, file.size);
        uploadAudio(blob);

        const blobUrl = URL.createObjectURL(file);

        setAudioBlobUrl(blobUrl);
    };

    async function uploadAudio(audio: Blob) {
        try {
            setTranscriptLoading(true);
            const response = await axios.post(
                'https://api-inference.huggingface.co/models/openai/whisper-large-v3',
                audio,
                {
                    headers: {
                        'Authorization': `Bearer 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`, //--production api key
                        'Content-Type': 'audio/*',
                    },
                },
            );

            const { data, status } = response;

            if (status === 200) {
                setTranscript(data.text);
                setTranscriptLoading(false);
            }
        } catch (error) {
            console.error(error);
            toast.error('Sorry, something went wrong. Check that you have a stable internet connection');
        }
    }

    const copyToClipboard = async () => {
        try {
            await navigator.clipboard.writeText(transcript);
            toast.success('Text copied to clipboard!');
        } catch (error: any) {
            toast.error('Unable to copy text to clipboard', error.message);
            console.log(error);
        }
    };

    function handleHumanize() {
        const assistant_content = transcript;
        console.log(assistant_content);
        localStorage.setItem('essay_data', assistant_content ?? '');
        const id = uuidv4();
        navigate(`/dashboard/article/editor?document=${id}`);
    }

    return (
        <>
            <Dialog open={openAlert} onClose={handleCloseAlert}>
                <DialogTitle>Error</DialogTitle>
                <DialogContent>Please upload an audio file before transcribing.</DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseAlert}>OK</Button>
                </DialogActions>
            </Dialog>

            <div style={{ display: 'flex', flexDirection: 'column' }}>
              
                <div className="record--audio--box mt-5 gap-7">
                    <div className="profile--row flex flex-row mb-3 gap-2">
                        <MyProfileIcon /> <span style={{ margin: '10px' }} className='dark:text-white'>  Record Audio To Transcribe</span>
                    </div>
                    <div>
                        <div className="upload--audio--controls w-full dark:border-white">
                            {uploading ? (
                                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '25px' }}>
                                    <CircularProgress style={{ color: '#E55109', width: '20px', height: '20px' }} />
                                    <div
                                        style={{
                                            fontFamily: 'Inter',
                                            fontSize: '0.75rem',
                                            fontWeight: 'normal',
                                            marginLeft: '10px',
                                        }}
                                        className="dark:text-white"
                                    >
                                        Uploading...
                                    </div>
                                </div>
                            ) : (
                                <>
                                    {uploadedFile ? (
                                        <div
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: '9px',

                                                flexDirection: 'row',
                                            }}
                                        >
                                            <img src={Music} alt="Music Icon" />
                                            <span className="file--name">{uploadedFile.name}</span>
                                            <img
                                                src={Close}
                                                alt="Close Icon"
                                                onClick={removeUploadFile}
                                                style={{ cursor: 'pointer' }}
                                            />
                                        </div>
                                    ) : (
                                        <div
                                            onClick={handleUploadButtonClick}
                                            style={{
                                                cursor: 'pointer',
                                                display: 'flex',
                                                gap: '18px',
                                                flexDirection: 'row',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <img src={Upload} alt="upload" />
                                            <span className='dark:text-white  '>Upload your audio file here</span>
                                        </div>
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                    <div className="transcribe--container">
                        <button
                            type="submit"
                            className="transcribe text-white bg-brandOrange font-medium"
                            onClick={handleTranscribed}
                        >
                            Transcribe
                        </button>
                    </div>
                </div>
                {transcribed ? (
                    <div className="pb-10">
                        <div style={{ display: 'flex', flexDirection: 'column', marginTop: '24px' }}>
                            <div className="flex items-center justify-between">
                            <img className='h-4 w-4' src={savedTheme === 'dark'? darkLogo:logo} alt='logo'/>
                                {isMobile && (
                                    <div className="flex items-center gap-8">
                                        <img className="w-5" src={Share} alt="" />
                                        <img className="w-5" src={Copy} alt="" />
                                        <img className="w-5" src={Download} alt="" />
                                    </div>
                                )}
                            </div>
                            <div
                                className="text-container"
                                style={{
                                    fontFamily: 'Inter',
                                    fontWeight: 400,
                                    fontSize: '13px',
                                    color: '#333237',
                                    overflow: 'hiden',
                                    overflowY: 'scroll',
                                    maxHeight: '300px',
                                    height: '200px',
                                    paddingRight: '10px',
                                    marginTop: '16px',
                                }}
                            >
                                <div>{transcriptLoading ? <Skeleton /> : transcript}</div>
                            </div>

                            <div
                                style={isMobile ? { justifyContent: 'flex-start', marginTop: '0.8rem' } : {}}
                                className="flex items-center justify-between w-full"
                            >
                                <div
                                    style={isMobile ? { width: 'fit-content' } : { width: '50%' }}
                                    className="flex gap-x-10"
                                >
                                    {!isMobile &&
                                        promptOptionsData.map((option, index) => (
                                            <div
                                                role="button"
                                                key={index}
                                                onClick={(e: any) => option.onClick(e)}
                                                className=" rounded-lg items-center px-2 py-1 bg-[var(--gmind-header)] flex gap-x-2"
                                            >
                                                <img src={option.icon} className="h-3" alt="" />
                                                <div className="font-normal text-[0.75rem] text-[var(--gmind-plain-black)]">
                                                    {option.name}
                                                </div>
                                            </div>
                                        ))}
                                </div>

                                <div className="flex gap-x-5 justify-end">
                                    <button
                                        type="button"
                                        onClick={() => {
                                            handleHumanize();
                                        }}
                                        className="flex items-center gap-2 pe-5"
                                    >
                                        <img className="w-4 h-4" src={Profile} alt="" />
                                        <span>Humanize</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <LoadingComponent isMobile={isMobile} width={''} />
                )}
            </div>
        </>
    );
};

export default UploadBox;
