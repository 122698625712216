import { useEffect, useRef, useState } from "react";
import ExampleCard from "../../../../components/example_card";
import curve from "@assets/curve.svg";
import learningGmind from "@assets/learning_gmind.svg";
import { useNavigate } from "react-router";
import { GetStartedForFree } from "@src/components/GetStartedButton";
import userIcon from "@icons/user_icon.svg";
import boostUseIcon from "@icons/boost_svg.svg";
import heroIcon from "@assets/essay/LandingIcon.png";
import useEndpoint from "../../auth/auth_endpoints";
import { toast } from "react-toastify";
import { getDeviceId } from "@src/utils/app_functions";

const About = ({isLoggedIn}: {isLoggedIn?: boolean;}) => {

    const texts = [
        'Creators',
        'Educators',
        'Learners',
        'Researchers',
      ];
    
    const [currentIndex, setCurrentIndex] = useState(0);
    const [currentText, setCurrentText] = useState('');
    const textRef = useRef(null);
    const navigate = useNavigate();

    useEffect(() => {
    const intervalId = setInterval(() => {
        if (textRef.current) {
        const nextText = currentText.substring(0, currentText.length - 1);
        setCurrentText(nextText);
        if (nextText.length === 0) {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % texts.length);
            setCurrentText(texts[currentIndex]);
        }
        }
    }, 2000); // Adjust interval for desired speed

    return () => clearInterval(intervalId);
    }, [texts.length]); 

    const handleGuestLogin = () => {
        function handleCallback() {
            const userData = localStorage.getItem('user');
            if (userData) {
                const userDetails = JSON.parse(userData);

                // if ( userDetails?.plan?.details !== undefined ){
                //     window.location.href = '/dashboard/chat';
                // }else {
                //     window.location.href = '/dashboard/pricing';
                // }
                window.location.href = '/dashboard/chat';
            }
        }

        const deviceId = getDeviceId();
        
        useEndpoint()
            .guestLogin({ deviceId})
            .then((res: any) => {
                if (res?.success) {
                    localStorage.setItem('token', res?.data?.token);
                    const { id, firstName, lastName, email, isVerified, plan } = res.data;
                    localStorage.setItem('user', JSON.stringify({ id, firstName, lastName, email, isVerified, plan }));
                    handleCallback();
                    toast.success(res?.message);
                } else {
                    toast.error(res?.message);
                }
            });
    }

    return (
        <>
            <div className="flex-row mt-1 w-[100%] hidden">
                <div className=" w-full gap-[5%] flex flex-row justify-between items-center">
                    <div className="w-full md:w-[50%] flex flex-col gap-y-5 mt-5">
                        <h3 className="font-medium text-[2rem] md:text-[2.5rem] text-[var(--gmind-black)]">
                        Leading AI tool for<span ref={textRef} className="text-[var(--gmind-orange)]"> {texts[currentIndex]} </span>.
                        </h3>
                        <h2 className="font-normal text-[1rem] text-[var(--gmind-light-black)]">
                        From designing curriculum to drafting lesson plans, creating attention-grabbing presentations and research papers, to forming unique questions and answers, GMind AI stands as a powerhouse to escalate the capabilities of each individual.
                        </h2>

                        <GetStartedForFree isLoggedIn={isLoggedIn} className="hidden md:flex w-[10rem]" onclick={() => {
                            if ( isLoggedIn ) {
                                navigate("/dashboard/chat");
                            }else {
                            navigate("/signup?forFree=1"); 
                            } 
                        }}  />

                        <div className="self-end flex gap-x-3">
                            <img src={curve} alt="curve" />
                            <img src={learningGmind} alt="learning gmind" />
                        </div>
                    </div>
                    
                    <ExampleCard />
                </div>

            </div>

            {/* {new hero section} */}
            <div className="flex flex-col gap-y-10 py-[3vh] z-50">
                <div className="w-full flex flex-col lg:flex-row items-center gap-x-3 md:justify-between">
               < img className="w-full xl:hidden block xl:w-[35%] md:w-[45%]  mt-[2vh] mb-[2vh]" src={heroIcon} alt="optimized" loading="lazy" />


                    <div className="flex flex-col gap-y-5 w-full lg:w-[45%]">
                        <h3 className="font-[Poppins] font-bold text-[2rem] md:text-[2.7rem] text-white">
                         {/* Smart AI Solutions for <span ref={textRef} className="text-[var(--gmind-orange)]"> {texts[currentIndex]}</span> */}
                         10x Your   <span ref={textRef} className="text-[var(--gmind-orange)] mt-5 lg-mt-0">Work Output</span> With GMind AI
                        </h3>

                        <p className="font-normal text-[0.8rem] md:text-[1rem] text-[#ffffff]" style={{
                            lineHeight: '1.8'
                        }} >Generate consistent, high-quality content with minimal prompts with structured templates. Reduce AI drift and boost efficiency for reliable, ready-to-publish results every time.        </p>

<p className="font-normal text-[0.8rem] mb-3 md:text-[1rem] text-[#ffffff]" style={{
                            lineHeight: '1.8'
                        }} > Smart AI assitant for teachers, creators, and business professionals.</p>
                        <div className="flex-col w-full gap-y-5 md:flex md:flex-row gap-x-3">
                            <div onClick={() => {
                                navigate('/features?showSection=1')
                            }} className="cursor-pointer rounded-md py-[0.6rem] px-[1.25rem] text-[#fcfbfb] border border-[#fffefe]">Watch Demo</div>

                            
                                <div onClick={() => {
                                    if ( isLoggedIn ) {
                                        navigate("/dashboard/chat");
                                    }else {
                                        handleGuestLogin();
                                        // navigate("/signup?forFree=1"); 
                                    } 
                                }} className=" cursor-pointer font-[Poppins] border-solid text-white text-[0.8rem] border-[1px] rounded-[0.625rem] border-[var(--gmind-orange)] px-[1.25rem] py-[0.6rem] bg-[var(--gmind-orange)]">{isLoggedIn ? 'Dashboard' : 'Get Started for Free'}</div>
                        </div>

                    </div>

                    <img className="w-full xl:block hidden md:w-[35%]  mt-[2vh]" src={heroIcon} alt="optimized" loading="lazy" />
{/* 
                    <div className="flex hidden self-end justify-center items-center mt-10 w-full">
                        <GetStartedForFree isLoggedIn={isLoggedIn} className="w-full" onclick={() => {
                            if ( isLoggedIn ) {
                                navigate("/dashboard/chat");
                            }else {
                                navigate ("/signup?forFree=1"); 
                            } 
                        }}  />
                    </div> */}


                    {/* <div className="self-start flex gap-x-5 mt-20 w-[19vw]">
                        <img src={curve} alt="curve" />
                    </div>

                    <div className="flex flex-col gap-y-5 w-[50vw]">
                        <h3 className="font-[Poppins] font-bold text-[2rem] md:text-[2.5rem] text-[var(--gmind-black)] text-center">
                        Simplifying teaching & content development with AI for <span ref={textRef} className="text-[var(--gmind-orange)]"> {texts[currentIndex]}</span>
                        </h3>
                        
                        <p className="font-[Poppins] text-[0.9rem] font-normal text-[var(--gmind-light-black)] text-center">GMind AI is a cutting-edge tool for educators. The leading AI-empowered educational tool that enhances teaching practices with time-saving solutions. From designing curriculum to drafting lesson plans, creating attention-grabbing presentations and research papers, to forming unique questions and answers, GMind AI stands as a powerhouse to escalate the capabilities of each individual.</p>

                        <div className="self-center flex gap-x-3">
                            <div onClick={() => navigate('/features?showSection=1') } className="cursor-pointer font-[Poppins] border-solid text-[var(--gmind-orange)] text-[0.8rem] border-[1px] rounded-[0.625rem] border-[var(--gmind-orange)] px-[1.25rem] py-[0.6rem] bg-transparent">Watch Demo</div>
                            <div onClick={() => {
                                if ( isLoggedIn ) {
                                    navigate("/dashboard/chat");
                                }else {
                                    navigate("/signup?forFree=1"); 
                                } 
                            }} className="cursor-pointer font-[Poppins] border-solid text-white text-[0.8rem] border-[1px] rounded-[0.625rem] border-[var(--gmind-orange)] px-[1.25rem] py-[0.6rem] bg-[var(--gmind-orange)]">{isLoggedIn ? 'Dashboard' : 'Get Started for Free'}</div>
                        </div>
                    </div>

                    */}
                </div>

                <div className="flex flex-col gap-2 pt-4  ">
                    <div className=" flex items-center justify-between gap-x-4  ">

                        <p className="text-white font-[Poppins]  text-[1rem] md:text-[1.25rem] font-normal" >Join the ranks of over 2M+ creators who have improved their workflow.</p>
                    </div>
                    
                       
                    
                </div>

                
            </div>
        </>
    );
}


export default About