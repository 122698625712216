import React, { useEffect, useRef, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import Header from '../components/header';
import LandingPage from '../root';
import './Layout.scss';
import { ToastContainer } from 'react-toastify';
import { getUrlPath } from '@src/utils/app_functions';
import DashboardFrame from '@src/components/DashboardFrame';
import FeaturesScreen from '../features';

interface LayoutProps {
    children?: React.ReactNode;
    isLoggedIn?: boolean;
}

const Layout = ({ children, isLoggedIn = false }: LayoutProps) => {
    const location = useLocation();
    const [isRoot, setIsRoot] = useState<boolean>(location.pathname === '/');
    const [isFeatures, setIsFeatures] = useState<boolean>(location.pathname === '/features');
    const faqRef = useRef<HTMLDivElement | null>(null);
    const featuresRef = useRef<HTMLDivElement | null>(null);
    const [isMobile, setIsMobile] = useState(true);
    const [showFrame, setShowFrame] = useState<boolean>(false);
    const [editorView, setEditorView] = useState<boolean>(false);
    const path = getUrlPath().pagename
    const [isHome, setIsHome] = useState(false);

    useEffect(() => {
        if (window.innerWidth >= 820) {
            setIsMobile(false);
        }
    }, [window.innerWidth]);

    useEffect(() => {
        let page = getUrlPath().pagename;
        if ( page == '' ){
            setIsHome(true);
        }else {
            setIsHome(false);
        }
    }, [window.location.pathname])

    useEffect(() => {
        let urlDetails = getUrlPath();
        if (urlDetails.pagename.toLowerCase() === 'pricing') {
            setShowFrame(true);
        } else if (urlDetails.pagename.toLowerCase() === 'editor') {
            setEditorView(true);
        } else {
            setShowFrame(false);
        }

        setIsRoot(location.pathname === '/');
        setIsFeatures(location.pathname === '/features');
    }, [window.location.pathname]);

    useEffect(() => {
        if (window.innerWidth >= 820) {
            setIsMobile(false);
        }
    }, [window.innerWidth]);
    const scrollToSection = (ref: React.RefObject<HTMLDivElement>) => {
        ref.current?.scrollIntoView({ behavior: 'smooth' });
    };
    const navigate = useNavigate();

    return (
        <div className={`${!editorView ? 'layout-container  flex justify-center items-start': 'w-screen'} min-h-screen ${ isHome ? 'bg-gradient-to-b from-[#001F73] to-[#00040D]' : 'bg-white' } `}>
            {showFrame ? (
                <DashboardFrame />
            ) : (
                <>
                    {!editorView ? (
                        <div className={`"layout max-w-1440 min-w-custom w-full h-full" ${path === '' ? 'bg-[#001F73]':'bg-[#001F73]'}`}>
                            <header>
                                <div className={`w-full px-10 md:px-20`}>
                                    <Header
                                        onClickFaQ={() => scrollToSection(faqRef)}
                                        onClickFeature={() =>
                                            isFeatures ? scrollToSection(featuresRef) : navigate('/features')
                                        }
                                        onClickPricing={() =>
                                            isLoggedIn ? navigate('/dashboard/pricing') : navigate('/pricing')
                                        }
                                        isLoggedIn={isLoggedIn ?? false}
                                        isPromo={false}
                                        showHeader={true}
                                    />
                                </div>
                               
                                <div className={`w-full`}>
                                    <Header
                                        onClickFaQ={() => scrollToSection(faqRef)}
                                        onClickFeature={() =>
                                            isFeatures ? scrollToSection(featuresRef) : navigate('/features')
                                        }
                                        onClickPricing={() =>
                                            isLoggedIn ? navigate('/dashboard/pricing') : navigate('/pricing')
                                        }
                                        isLoggedIn={isLoggedIn ?? false}
                                        isPromo={true}
                                    />
                                </div>
                            </header>
                            <main className='w-full'>
                                {isRoot ? (
                                    <LandingPage isLoggedIn={isLoggedIn} faqRef={faqRef} />
                                ) : isFeatures ? (
                                    <FeaturesScreen isLoggedIn={isLoggedIn} faqRef={faqRef} featuresRef={featuresRef} />
                                ) : (
                                    <Outlet />
                                )}
                            </main>
                        </div>
                    ) : (
                        <Outlet />
                    )}
                </>
            )}
            <ToastContainer />
        </div>
    );
};

export default Layout;
