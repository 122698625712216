import { FC, useContext } from 'react';
import clsx from 'clsx';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import SyntaxHighlighter from 'react-syntax-highlighter';
import agate from 'react-syntax-highlighter/dist/esm/styles/hljs/agate';
import 'github-markdown-css';
import './index.css';
// import './index_dark.css';
import { ThemeContext } from '@src/theme_provider/theme';

interface ChatContent {
    text?: string;
}

interface ChatMessageProps {
    role: string;
    className?: string;
    content: string | ChatContent[];
}

function isChatContentArray(content: any): content is ChatContent[] {
    return (
        Array.isArray(content) &&
        content.every((item) => typeof item.text === 'string' || typeof item.text === 'undefined')
    );
}

function ChatMessage({ role, content, className }: ChatMessageProps) {
    // const { savedTheme } = useContext(ThemeContext);
    const messageContent =
        typeof content === 'string'
            ? content
            : isChatContentArray(content) && content.length > 0
              ? content[0].text || ''
              : '';

    return (
        <>
            {messageContent && (
                <div
                    className={`flex ${role === 'assistant' ? 'justify-start' : 'flex justify-end'} font_Poppins text-[#333237]  text-[16px] my-2`}
                >
                    <div
                        className={`rounded-xl ${role === 'assistant' ? 'text-left w-full bg-transparent' : 'p-3 text-left max-w-[90%] min-w-[20%] dark:bg-gray-800 bg-white'}`}
                    >
                        <ChatMarkDown className={className} messageContent={messageContent} />
                    </div>
                </div>
            )}
        </>
    );
}

export default ChatMessage;

export const ChatMarkDown = ({ className, messageContent }: { className?: string; messageContent?: string }) => {
    const { savedTheme } = useContext(ThemeContext);

    return (
        <div className={clsx(className, `${savedTheme === 'dark' ? 'mode' : 'markdown-body'}`, '!font-optimistic')}>
            <ReactMarkdown
                children={messageContent}
                remarkPlugins={[remarkGfm]}
                rehypePlugins={[rehypeRaw]}
                components={{
                    code({ className, children, ...props }) {
                        const match = /language-(\w+)/.exec(className || '');
                        return match ? (
                            <SyntaxHighlighter
                                style={agate}
                                language={match[1]}
                                showLineNumbers
                                lineNumberStyle={{ color: '#999' }}
                                PreTag="div"
                                {...props}
                            >
                                {String(children).replace(/\n$/, '')}
                            </SyntaxHighlighter>
                        ) : (
                            <code className={className} {...props}>
                                {children}
                            </code>
                        );
                    },
                }}
            />
        </div>
    );
};
