import { Link, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import logo from '@assets/gmind_white.png';
import normallogo from '@assets/gmind_white.png';
import hamburger from '@icons/menu-2.svg';
import { getUrlPath } from '@src/utils/app_functions';
import { GetStartedButton } from '@src/components/GetStartedButton';
import PromoHeader from '../../pricing/components/promo_price';
import { FaHamburger } from 'react-icons/fa';
import { Menu } from 'lucide-react';
import { UserDetailsData } from '@src/core/interfaces/user';
import { GetUser } from '@src/core/endpoints/user';

type HeaderProps = {
    onClickFaQ: () => void;
    onClickFeature: () => void;
    onClickPricing: () => void;
    isLoggedIn: boolean;
    isPromo?: boolean;
    showHeader?: boolean;
};

const Header = ({ onClickFaQ, onClickFeature, onClickPricing, isLoggedIn = false, isPromo, showHeader = true }: HeaderProps) => {
    const navigatePage = useNavigate();
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [showSections, setShowSections] = useState<boolean>(true);
    const [isMobile, setIsMobile] = useState(true);
    const [isClose, setIsClose] = useState(true);
    const [isGuest, setIsGuest] = useState(true);
    const  path = getUrlPath().pagename
    

    const getDetails = async () => {
        const token = localStorage.getItem('token');
        if (token) {
            const details = await GetUser(token);
            if (details.statusCode == 1) {
                const { id, firstName, lastName, email, isVerified, plan, freeTrialAvailable, daysRemaining } =
                    details.data;
                const detailsFetched: UserDetailsData = details.data;
                setIsGuest(detailsFetched?.isGuest ?? false);
            } 
        }
    };
    

    useEffect(() => {
        getDetails();
        const pagePathDetails = getUrlPath();
        let pagename = pagePathDetails.pagename;

        const pages = ["cookies", "privacy-policy", "terms-of-service"];

        if (pagename != '' && pagename != 'features'  && !pages.includes(pagename) && !isMobile  ) {
            setShowSections(false);
        }else {
            setShowSections(true);
        }
    }, [window.location.pathname, isMobile]);


    return (
       <div className='w-full flex flex-col gap-1 '>
       { !isPromo && <div className={`w-full flex justify-center px-2 md:px-4 py-4 md:p-4`}>
            <div className="w-full flex flex-row justify-between items-center">
                <Link to="/">
                    <img src={logo} alt="G-mind logo" className="w-[5rem]" />
                </Link>

                {
                    showHeader && (
                        <>
                            {showSections && (
                    <div className="hidden md:flex items-center justify-center ml-6 gap-10">
                        <div
                            onClick={onClickFaQ}
                            className="text-[16px] text-white font-medium cursor-pointer"
                        >
                            FAQ
                        </div>
                        <div
                            onClick={onClickFeature}
                            className="text-[16px] text-white font-medium cursor-pointer"
                        >
                            Features
                        </div>
                        <div
                            onClick={onClickPricing}
                            className="text-[16px] text-white font-medium cursor-pointer"
                        >
                            Pricing
                        </div>

                        <a 
                            href='https://thegmindai.com/blog/'
                            className="text-[16px] text-white font-medium cursor-pointer"
                            target="_blank" 
                            rel="noopener noreferrer"
                        >
                            Blog
                        </a>
                    </div>
                )}

                <Menu
                    className="flex lg:hidden cursor-pointer text-white"
                   
                    onClick={() => setIsMenuOpen(!isMenuOpen)}
                />

                {showSections && (
                    <div className="hidden lg:flex gap-x-3 justify-end">
                        {
                            !isLoggedIn && (
                                <GetStartedButton className="w-[10rem]" onclick={() => {
                                        if ( isLoggedIn ) {
                                            navigatePage("/dashboard/chat");
                                        }else {
                                            navigatePage("/signup?forFree=1"); 
                                        } 
                                    }}
                                    showforFree={true} 
                                />    
                            ) 
                        } 
                        {
                             (
                                <button
                                    onClick={() => {
                                        if ( isLoggedIn ){
                                        window.localStorage.removeItem("token");
                                        window.location.reload();
                                        return; 
                                        }
                                        navigatePage('/login')
                                    } }
                                    className="hidden md:flex justify-center items-center px-5 py-2 bg-transparent text-white rounded-[10px] border border-white"
                                >
                                { isLoggedIn ? 'Logout': 'Login' }
                                </button>  
                            )
                        }
                        
                    </div>
                    
                )}

                {isMenuOpen && showSections && (
                    <div className="absolute w-60 top-16 right-4 bg-white shadow-md rounded-lg p-4 flex flex-col items-start gap-4 lg:hidden z-50">
                        <div
                            onClick={ () => {
                                onClickFaQ();
                                setIsMenuOpen(!isMenuOpen); 
                            }}
                            className="text-[16px] text-[var(--gmind-black)] font-medium cursor-pointer"
                        >
                            FAQ
                        </div>
                        <div
                            onClick={() => { 
                                onClickFeature();
                                setIsMenuOpen(!isMenuOpen)
                            }}
                            className="text-[16px] text-[var(--gmind-black)] font-medium cursor-pointer"
                        >
                            Features
                        </div>
                        <div
                            onClick={() => {
                                setIsMenuOpen(!isMenuOpen)
                                navigatePage('/pricing')
                            }}
                            className="text-[16px] text-[var(--gmind-black)] font-medium cursor-pointer"
                        >
                            Pricing
                        </div>

                        <div
                            onClick={() => {
                                setIsMenuOpen(!isMenuOpen)
                                // window.location.href=`https://thegmindai.com/blog/`
                                window.open('https://thegmindai.com/blog/', '_blank', 'noopener,noreferrer');
                            }}
                            className="text-[16px] text-[var(--gmind-black)] font-medium cursor-pointer"
                        >
                            Blog
                        </div>
                        {
                            (
                                <button
                                    onClick={
                                        () => {
                                            setIsMenuOpen(!isMenuOpen);
                                        if ( isLoggedIn ){
                                        window.localStorage.removeItem("token");
                                        window.location.reload();
                                        return; 
                                        }
                                        navigatePage('/login')
                                    }
                                }
                                    className="w-full px-5 py-2 bg-white text-[var(--gmind-orange)] rounded-[10px] border border-[var(--gmind-orange)] flex justify-center items-center"
                                >
                                    { isLoggedIn ? 'Logout': 'Login' }
                                </button>
                            )
                        }
                        
                    </div>
                )}
                        </>

                    )
                }

                
            </div>
        </div>}
        {isPromo && (
                <div className="w-full"> {/* Negative margin to offset any padding */}
                    <PromoHeader isLayout = {true}  closeClick={()=> setIsClose(false)}/>
                </div>
            )}
        </div>
    );
};

export default Header;
