import { BASE_URL } from '@src/utils/constants';
import axios from 'axios';

export const GetHistory = async (token, path) => {
    const response = await axios.get(`${BASE_URL}/gmind/${path}`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    const { data } = response;

    return data;
};

export const SaveHistory = async (token, data) => {
    const response = await axios.post(`${BASE_URL}/gmind/chats`, data, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });

    const { data: d } = response;
    return d;
};
