import { GetHistory } from "@src/core/endpoints/chat";
import { getUrlPath } from "@src/utils/app_functions";

export const  getAllHistory= ()=>{
    const path = getUrlPath();

    if (path.pagename.includes('chat')){
       const data = GetHistory(localStorage.getItem('token'),'chats');
       return data;
    }else if (path.pagename.includes('curriculum')){
       const data = GetHistory(localStorage.getItem('token'),'curriculum');
       return data;
    }else if (path.pagename.includes('generate_content')){
       const data = GetHistory(localStorage.getItem('token'),'contents');
       
        return data;
    }else if (path.pagename.includes('essay')){
      const data =  GetHistory(localStorage.getItem('token'),'essay');
      return data;
    }else if (path.pagename.includes('assessments')){
       const data = GetHistory(localStorage.getItem('token'),'assessments');
       return data;
    }else if (path.pagename.includes('class')){
       const data = GetHistory(localStorage.getItem('token'),'slide');
       return data;
    } else if (path.pagename.includes('research')){
       const data =  GetHistory(localStorage.getItem('token'),'research');
       return data;
    } else if (path.pagename.includes('slide')){
      const data =  GetHistory(localStorage.getItem('token'),'slide');
      return data;

    }
    else{
        return null;
    }

}