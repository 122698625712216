import { useEffect, useRef, useState } from 'react';
import BaseForm from './base_form';
import { Form, Input } from 'antd';
import { activityGeneratorPrompt, InputType } from '../interfaces';
import {
    activityOptions,
    availabilityOptions,
    curriculumOptions,
    durationOptions,
    lessonOptions,
    pedagogicalStatements,
    pedagogyOptions,
    pedgogyDescription,
    sizeOptions,
    skillOptions,
    standardOptions,
} from '../constants';
import BrowseModal from './browseModal';
import BrowseTeachingActivities from './browser_activities';
import teachingStandardSets from '@src/utils/teaching_standards';
import { TeachingStandardSet } from '@src/utils/teaching_standards';
import AIGenerate from '@icons/generate.svg';
import { toast } from 'react-toastify';
import { io, Socket } from 'socket.io-client';
import { prompts } from '@src/utils/prompts';
import { onGenerateWithAI } from '../functions';
import { BASE_URL, socketUrl } from '@src/utils/constants';

const ActivityGenerator = ({ onGenerate, onSetTopic }: activityGeneratorPrompt) => {
    const [subject, setCurrentSubject] = useState('');
    const [isMobile, setIsMobile] = useState(true);
    const [basePrompt, setBasePrompt] = useState('');
    const [conceptForm, setConceptForm] = useState('');
    const [skill, setSkill] = useState('');
    const [activity, setActivity] = useState('');
    const [lesson, setLesson] = useState('');
    const [groupSize, setGroupSize] = useState('');
    const [duration, setDuration] = useState('');
    const [specialNeedsAvailability, setSpecialNeedsAvailability] = useState('');
    const [interest, setInterest] = useState('');
    const [visible, setVisible] = useState(false);
    const [firstPrompt, setFirstPrompt] = useState<string>('');
    const [selectedActivity, setSelectedActivity] = useState<TeachingStandardSet>({ name: '', description: '' });
    // const [sockets, setSockets] = useState<Socket | null>(null);
    const [sockets, setSockets] = useState<{ [key: string]: Socket | null }>({});
    const [visibility, setVisibility] = useState<{ [key: string]: boolean }>({});
    const textAreaRef = useRef<HTMLTextAreaElement>(null);
    const [inputTyping, setInputTyping] = useState<{ [key: string]: boolean }>({});
    const [pedagogy, setPedgogy] = useState('');

    const baseurl = BASE_URL;

    useEffect(() => {
        if (window.innerWidth >= 820) {
            setIsMobile(false);
        }
    }, [window.innerWidth]);

    const allInputs: Array<InputType> = [
        {
            value: conceptForm,
            type: 1,
            name: 'conceptForm',
            label: 'Activity Focus*',
            placeholder:
                'The activity focus or theme that the learning activity should emphasize\ne.g students should be able identify and describe refraction of light',
            onChange: (e) => {
                setConceptForm(e);
            },
            options: [],
            onGenrate: () => {
                let prompt = `Generate a short activity focus for the activity for ${subject}`;
                const socket = sockets['conceptForm'];
                onGenerateWithAI(socket, prompt);
            },
            setData: setConceptForm,
        },
        {
            value: skill,
            type: 2,
            name: 'skill',
            label: 'Skill Focus*',
            placeholder: 'Select',
            onChange: (e) => {
                setSkill(e);
            },
            options: skillOptions,
            onGenrate: () => {},
            setData: setSkill,
        },
        {
            value: activity,
            type: 2,
            name: 'activity',
            label: 'Activity Type*',
            placeholder: 'Select',
            onChange: (e) => {
                setActivity(e);
            },
            options: activityOptions,
            onGenrate: () => {},
            setData: setActivity,
        },
        {
            value: lesson,
            type: 2,
            name: 'lesson',
            label: 'Lesson Stage*',
            placeholder: 'Select',
            onChange: (e) => {
                setLesson(e);
            },
            options: lessonOptions,
            onGenrate: () => {},
            setData: setLesson,
        },
        {
            value: groupSize,
            type: 2,
            name: 'group',
            label: 'Group Size*',
            placeholder: 'Select',
            onChange: (e) => {
                setGroupSize(e);
            },
            options: sizeOptions,
            onGenrate: () => {},
            setData: setGroupSize,
        },
        {
            value: duration,
            type: 2,
            name: 'duration',
            label: 'Duration*',
            placeholder: 'Select',
            onChange: (e) => {
                setDuration(e);
            },
            options: durationOptions,
            onGenrate: () => {},
            setData: setDuration,
        },
        {
            value: pedagogy,
            type: 2,
            name: 'Activity Pedagogy',
            label: 'Activity Pedagogy*',
            placeholder: 'Select',
            onChange: (e) => {
                setPedgogy(e);
            },
            options: pedagogyOptions,
            onGenrate: () => {},
            setData: setPedgogy,
        },
        {
            value: specialNeedsAvailability,
            type: 2,
            name: 'availabity',
            label: 'Special Needs Availability*',
            placeholder: 'Select',
            onChange: (e) => {
                setSpecialNeedsAvailability(e);
            },
            options: availabilityOptions,
            onGenrate: () => {},
            setData: setSpecialNeedsAvailability,
        },
        {
            value: interest,
            type: 1,
            name: 'interest',
            label: 'Student Interests*',
            placeholder: 'Any specific student interest to consider?\ne.g. My students like experiements',
            onChange: (e) => {
                setInterest(e);
            },
            options: [],
            onGenrate: () => {
                let prompt = `Generate a short student interests for the activity for ${subject}`;
                const socket = sockets['interest'];
                onGenerateWithAI(socket, prompt);
            },
            setData: setInterest,
        },
    ];

    useEffect(() => {
        const newSockets: { [key: string]: Socket } = {};
        const newVisibility: { [key: string]: boolean } = {};
        const newTyping: { [key: string]: boolean } = {};
        allInputs.forEach((item) => {
            newSockets[item.name] = io(socketUrl.slice(0, -2));
            newVisibility[item.name] = true;
            newTyping[item.name] = false;
        });
        setSockets(newSockets);
        setVisibility(newVisibility);
        setInputTyping(newTyping);

        return () => {
            Object.values(newSockets).forEach((socket) => {
                socket.close();
            });
        };
    }, []);

    const setTyping = (key: string, value: boolean) => {
        setInputTyping((prevData) => ({
            ...prevData,
            [key]: value,
        }));
    };

    useEffect(() => {
        allInputs.forEach((item) => {
            const socket = sockets[item.name];
            if (!socket) return;

            const handleData = (data: string) => {
                item.setData((response) => response + data);
            };
            const handleStreamEnd = () => {
                setTyping(item.name, false);
            };

            socket.on('data', handleData);
            socket.on('stream_end', handleStreamEnd);

            return () => {
                socket.off('data', handleData);
                socket.off('stream_end', handleStreamEnd);
            };
        });
    }, [sockets]);

    const onInitialGenerate = () => {
        if (
            !conceptForm ||
            !skill ||
            !activity ||
            !lesson ||
            !groupSize ||
            !duration ||
            !specialNeedsAvailability ||
            !interest ||
            !pedagogy
        ) {
            toast.error('Input field cannot be empty');
            return;
        }

        let conceptData = `Concept the learning activity should emphasize: ${conceptForm}\n`;
        let skillData = `Skill to focus on: ${skill}\n`;
        let activityData = `Activity Type: ${activity}\n`;
        let lessonData = `Lesson Stage: ${lesson}\n`;
        let groupSizeData = `Learning group size: ${groupSize}\n`;
        let durationData = `Learning Duration: ${duration}`;
        let specialNeedsData = `Special Needs Availability: ${specialNeedsAvailability}`;
        let interestData = `Student interests: ${interest}`;
        let pedagogyData = `Pedagogy: ${pedagogy} with description ${pedgogyDescription(pedagogy)}`;

        let prompt = `Kindly generate a set of learning activities using this details below:\n${conceptData}${skillData}${activityData}${lessonData}${groupSizeData}${durationData}${specialNeedsData}${interestData}${pedagogyData}. Kindly ensure all the details sent is shown in the response. Also ensure that the response is grade level appropriate and strictly follows the requested curriculum`;

        onGenerate(prompt, 'activity');
        // setVisible(true);
    };

    const generate = () => {
        let teachingStandard = `Using this teaching standard, Standard: ${selectedActivity.name}\n Standard Description: ${selectedActivity.description}`;
        let promptGenerated = `${firstPrompt} ${teachingStandard} with the following base info ${basePrompt}`;
        setVisible(false);
        onGenerate(promptGenerated, 'activity');
    };

    const setAIVisible = (key: string) => {
        setVisibility((prevData) => ({
            ...prevData,
            [key]: false,
        }));
    };

    const hasTrueValue: boolean = Object.keys(inputTyping).some((key: string) => inputTyping[key]);

    return (
        <div className={`px-0 md:px-[3rem] w-full flex flex-col gap-y-3`}>
            {subject == '' && (
                <div className={``}>
                    <div className="flex flex-col gap-y-2 w-full mb-2">
                        <h2 className="font-medium dark:text-white text-[2rem] md:text-[2.75rem] text-[var(--gmind-black)]">
                            Learning Activity Generator
                        </h2>
                        <p className="text-[1.125rem] dark:text-gray-300 md:text-[0.875rem] text-[var(--gmind-white60)] text-normal">
                            Provide us the information requested below to get your activity ready.
                        </p>
                    </div>
                    <BaseForm
                        dropDownOptions={curriculumOptions}
                        firstText="Curriculum*"
                        onSubmit={(baseValue) => {
                            setCurrentSubject(baseValue.subject);
                            onSetTopic(baseValue.subject);
                            setBasePrompt(baseValue.basePrompt);
                        }}
                    />
                </div>
            )}
            {subject != '' && (
                <div className="flex flex-col gap-y-3 w-full">
                    {/* <div className="items-center flex gap-x-3">
                        <p className="font-semibold text-[0.9rem] text-[var(--gmind-light-black)]">{subject}</p>
                        <button className="p-1 rounded-[0.25rem] text-[var(--gmind-orange)] text-[0.9rem]" style={
                            {
                                border: "1px solid var(--gmind-orange)"
                            }
                        }>Learning Activity Generator</button>
                    </div> */}

                    <h2
                        className="whitespace-normal font-medium text-[2rem] text-[2.75rem] dark:text-white text-[var(--gmind-black)]"
                        style={{ lineHeight: '2.8rem' }}
                    >
                        Generate several learning activities and choose one.
                    </h2>
                    <p className="text-[1.125rem] md:text-[0.875rem] text-[var(--gmind-white60)] text-normal dark:text-white">
                        Outline the criteria you have in mind for the learning activity you'd like to employ.
                    </p>

                    <Form onFinish={onInitialGenerate} className="flex flex-col gap-y-3 " layout="vertical">
                        {allInputs.map((item, index) =>
                            item.type === 1 ? ( // Use === for strict comparison
                                <div className="">
                                    <Form.Item
                                        key={index}
                                        className="w-full"
                                        name={item.name}
                                        label={
                                            <label className="font-normal text-[0.9rem] text-[var(--gmind-light-black)] dark:text-white">
                                                {item.label}
                                            </label>
                                        }
                                        rules={[
                                            {
                                                message: 'field is required',
                                                required: false,
                                            },
                                        ]}
                                    >
                                        <div className="relative">
                                            <Input.TextArea
                                                ref={textAreaRef}
                                                className="rounded-[0.25rem] placeholder-gray-700 bg-transparent hover:bg-transparent"
                                                autoSize={{ minRows: 4 }}
                                                style={{ border: '1px solid gray' }}
                                                value={item.value}
                                                onChange={(e) => item.onChange(e.target.value)} // No need for extra parenthesis
                                                placeholder={item.placeholder}
                                            />
                                            {visibility[item.name] && (
                                                <div
                                                    onClick={() => {
                                                        item.onGenrate();
                                                        setAIVisible(item.name);
                                                        setTyping(item.name, true);
                                                    }}
                                                    className="cursor-pointer shadow-md rounded-[1rem] absolute top-[55px] right-[10px] py-[0.3rem] px-[1rem] bg-white text-normal text-[0.75rem] text-[var(--gmind-black)] flex gap-x-2"
                                                >
                                                    <img src={AIGenerate} alt="" />
                                                    <span>Use Gmind AI</span>
                                                </div>
                                            )}
                                        </div>
                                    </Form.Item>
                                </div>
                            ) : (
                                
                                <Form.Item
                                    className="w-full flex flex-col gap-y-3"
                                    name={item.name}
                                    label={<label className=" dark:text-white curriculum--label">{item.label}</label>}
                                    rules={[
                                        {
                                            message: 'field is required',
                                            required: false,
                                        },
                                    ]}
                                >
                                    <select
                                        className="w-full placeholder-gray-700 dark:text-white"
                                        value={item.value}
                                        onChange={(e) => item.onChange(e.target.value)}
                                        style={{
                                            border: '1px solid gray',
                                            borderRadius: '4px',
                                            backgroundColor: 'transparent',
                                            padding: '7px 10px',
                                        }}
                                    >
                                        <option
                                            className={`font-normal text-[0.9rem]   rounded-[0.3rem] px-[1rem] py-[0.5rem]`}
                                            value={''}
                                        >
                                            Select {item.label.replace('*', '')}
                                        </option>
                                        {item.options.map((item2, index2) => (
                                            <option
                                                className={`${item.value === item2 ? 'bg-[var(--gmind-orange8)] text-[var(--gmind-orange)]' : ''} font-normal text-[0.9rem] rounded-[0.3rem] px-[1rem] py-[0.5rem]`}
                                                key={index2}
                                                value={item2}
                                            >
                                                {item2}
                                            </option>
                                        ))}
                                    </select>
                                </Form.Item>
                            ),
                        )}
                        <button
                            disabled={hasTrueValue}
                            type="submit"
                            className="w-[11rem] p-3 rounded-[0.375rem] text-white bg-[var(--gmind-orange)]"
                        >
                            Generate Activities
                        </button>
                    </Form>
                    <BrowseTeachingActivities
                        onCancel={() => setVisible(false)}
                        visible={visible}
                        cardsData={teachingStandardSets}
                        click={(card) => {
                            setSelectedActivity(card);
                            generate();
                        }}
                    />
                </div>
            )}
        </div>
    );
};

export default ActivityGenerator;
