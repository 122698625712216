import textLogo from '@assets/text_logo.svg';
import textLogoDaark from '@assets/essay/Gmind_Logo_Compressed.png';

import { ThemeContext } from '@src/theme_provider/theme';
import { useContext } from 'react';

const ContentTypingComponent = ({isTyping}: {isTyping: boolean}) => {
    const {savedTheme} = useContext(ThemeContext)
    return ( 
        <div className='mx-[25%] w-[200px] flex items-center gap-x-3 font-normal text-[var(--gmind-orange)] dark:text-white text-[0.8rem] rounded-[0.8rem] self-center border border-[var(--gmind-orange)] dark:border-white px-3 py-2'>
            {isTyping && savedTheme === 'dark'?<img src={textLogoDaark} className='h-4' alt="logo" />:  <img src={textLogo} alt="logo" />}
            <p>
                {isTyping ? 'is Typing' : 'Content is Ready'}
            </p>
        </div>
    );
}

export  default ContentTypingComponent;