import React, { useState } from 'react';
import Email from '@icons/envelope-line-icon.svg';
import EmailVerified from '@icons/mail-checkmark-icon.svg';
import ArrowRight from '@icons/arrow-right.svg';
import Foot from '@icons/Footer.svg';
import BackButton from '@src/components/BackButton';

interface EmailProps {
    email: string;
}

const EmailVerification = () => {
    const [isVerified, setVerified] = useState(false);
    return (
        <div className="w-full flex flex-col items-center justify-between" id="Main" style={{ height: '100%' }}>
            <>
                {!isVerified ? (
                    <div className="mt-10 w-full h-full flex flex-col items-center md:justify-center ">
                        <img
                            alt="email"
                            src={EmailVerified}
                            className="h-[4.5rem] w-[4.5rem] md:h-[10.5rem] md:w-[12.5rem] "
                        />
                        <div
                            className="font-montserrat text-[0.8125rem] md:text-lg font-medium leading-6 text-center mt-4"
                            style={{ color: '#4B465C' }}
                        >
                            Your email has been verified successfully.
                        </div>

                        <button className=" mt-[56px] md:mt-4 px-4 py-2 bg-customOrange text-white font-medium  text-sm md:text-lg rounded">
                            <div className="flex flex-row items-center gap-x-2">
                                Explore
                                <img alt="email" src={ArrowRight} className="max-w-full h-auto" />
                            </div>
                        </button>
                    </div>
                ) : (
                    <div className="w-full h-full flex flex-col items-center md:justify-center">
                        <img alt="email" src={Email} className="h-[4.5rem] w-[4.5rem] md:h-[10.5rem] md:w-[12.5rem]" />
                        <p
                            className="font-montserrat text-[0.8125rem] md:text-lg font-medium leading-6 text-center mt-4"
                            style={{ color: '#4B465C' }}
                        >
                            An email has been sent to email.<p>Check your mail to verify.</p>
                        </p>
                    </div>
                )}
            </>

            <img alt="foot" src={Foot} className="w-full" />
        </div>
    );
};

export default EmailVerification;