import DashboardFrame from '@src/components/DashboardFrame';
import { useCallback, useEffect, useRef, useState } from 'react';
import {
    Chats,
    ChatType,
    getHistory,
    handleAddHistory,
    handleDeleteAllWithTypeHistory,
    handleDeleteHistory,
} from '../../endpoints';
import right from '@assets/right_gray.svg';
import { Download } from '../../components/Share/Download';
import ReusableDialog from './components/CommentModal';
import LoadingComponent from '@src/components/LoadingComponent';
import ResponseTextbox from '../../components/responseTextBox';
import MarkdownDesign from '@src/components/Markdown';
import NewGenerateLetter from './components/new_generate_home';
import BackButton from '@src/components/BackButton';
import { contentPromptOptionsData } from './constants';
import { toast } from 'react-toastify';
import { prompts } from '@src/utils/prompts';
import { io, Socket } from 'socket.io-client';
import { Input } from 'antd';
import { Share } from '../../components/Share';
import moment from 'moment';
import { convertStringToDelta, openEditor } from '@src/utils/app_functions';
import saveAs from 'file-saver';
import { pdfExporter } from 'quill-to-pdf';
import axios from 'axios';
import Profile from '@assets/Avatar.svg';
import { v4 as uuidv4 } from 'uuid';
import ContentTypingComponent from '../../components/content_typing';
import { useNavigate, useOutletContext } from 'react-router';
import { BASE_URL } from '@src/utils/constants';
import { HistoryTypeFormat } from '@src/core/interfaces/chat';

const BusinessResources = () => {
    const pageHistory = 'contents';
    const navigate = useNavigate();
    const [isMobile, setIsMobile] = useState(true);
    const [histories, setHistories] = useState<any[]>([]);
    const [page, setPage] = useState<string>('');
    const [generating, setGenerating] = useState(false);
    const [response, setResponse] = useState('');
    const [isTyping, setIsTyping] = useState(false);
    const [generate, setGenerate] = useState(false);
    const [sideBarVisible, setSidebarVisible] = useState<boolean | null>(null);
    const [basePage, setBasePage] = useState<number>(0);
    const [showEditorButton, setShowEditorButton] = useState(false);
    const UrlRef = useRef<string>('');
    const [showDownload, setShowDownload] = useState<boolean>(false);
    const [showShareModal, setShowShareModal] = useState<boolean>(false);
    const [dialogVisible, setDialogVisible] = useState(false);
    const [chats, setChats] = useState<Chats>({ role: '', content: '', type: 'gpt-4' });
    const [promptSent, setPromptSent] = useState('');
    const [newPromptSent, setNewPromptSent] = useState('');
    const [socket, setSocket] = useState<Socket | null>(null);
    const [message, setMessage] = useState('');
    const StreamId = useRef<any>();
    const DocumentId = useRef('');
    const [canNavigate, setCanNavigate] = useState(false);
    const [topic, setTopic] = useState('');
    const [basePrompt, setBasePrompt] = useState('');
    const [docId, setDocId] = useState<string | undefined>(undefined);
    const [showingHistory, setShowingHistory] = useState(false);
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [historyType, setHistoryType] = useState<string>('content');
    const [conversation, setConversation] = useState<ChatType[]>([]);
    const baseurl = BASE_URL;
    const [streamEndMessage, setStreamEndMessage] = useState<ChatType>({ role: 'assistant', content: '' });
    const {
        setShowMobileHistory,
        setShowTop,
        setShowSidebar,
        setRightComponentName,
        setRightComponentPlaceholder,
        setRightComponentData,
        setHistory,
        setMiddleClass,
        setMobileNavStyle,
        selectedHistory,
        setShowPagePath,
        setTopExtraData,
        setCloseIdValue,
        setShowHistoryOption,
        setGmindTyping,
        setCanNavigateHome,
        setShowExpiredModal,
        setShowCompleteProfileModal,
        setIsAffiliate,
        setMenu,
        setShowInput,
        setShowHistoryOptions,
        setRightComponentClick,
        setOnDetailsFetched,
        setOnDeleteAllHistory,
        setOnDeleteHistory,
        setOnClickPage,
        setSelectedHistory,
        setValue,
        setShowRightComponent,
        setSchoolAdminPageName,
        setShowSchoolHeader,
        setShowHistory,
    } = useOutletContext<any>();

    const bottomRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        if (isTyping && response != '') {
            console.log('scrolling');
            bottomRef.current?.scrollTo({
                top: bottomRef.current?.scrollHeight,
                behavior: 'smooth',
            });
        }
    }, [isTyping, response]);
    const getPageHistory = useCallback(async () => {
        getHistory(pageHistory).then((response) => {
            if (response?.statusCode === 1) {
                const data: any[] = response?.data;
                const filtered: HistoryTypeFormat[] = data
                    ?.map((d: any) => {
                        return {
                            date: d.date,
                            histories: d.histories
                                ?.map((history: any) => {
                                    if (history.type == historyType) {
                                        return [
                                            ...history.conversation,
                                            { id: history._id },
                                            { type: history.type },
                                            { date: history.updatedAt },
                                        ];
                                    } else {
                                        return [];
                                    }
                                })
                                .filter((element: any) => element.length > 0),
                        };
                    })
                    .filter((element: any) => element.histories.length > 0);
                console.log(filtered);
                setHistories(filtered);
            } else {
                setHistories([]);
            }
        });
    }, [pageHistory, historyType, setHistories]);
    useEffect(() => {
        getPageHistory();
    }, [getPageHistory]);

    const promptOptions = contentPromptOptionsData({
        response,
        onSuccess: setShowShareModal,
        urlRef: UrlRef,
        showDownload: setShowDownload,
    });

    useEffect(() => {
        const newSocket = io(BASE_URL.slice(0, -2));
        setSocket(newSocket);
        return () => {
            newSocket.close();
        };
    }, []);

    useEffect(() => {
        if (currentPage == 1 || currentPage == 3) {
            setHistoryType('content');
        } else if (currentPage == 2) {
            setHistoryType('learning_materials');
        } else if (currentPage == 4) {
            setHistoryType('letter');
        } else if (currentPage == 5) {
            setHistoryType('social');
        }

        console.log(currentPage);
    }, [currentPage]);

    useEffect(() => {
        if (conversation.length == 0) return;
        handleAddHistory(conversation, pageHistory, historyType);
        getPageHistory();
        setConversation([]);
    }, [conversation, getPageHistory, historyType, isTyping]);

    useEffect(() => {
        if (!socket) return;

        socket.on('data', (data: string) => {
            setResponse((response) => response + data);
        });

        socket.on('stream_end', async (data: { streamId: string; assistant: ChatType }) => {
            const { streamId, assistant } = data;
            setStreamEndMessage(assistant);
            setShowEditorButton(true);
            setIsTyping(false);
            setCanNavigate(true);
            setConversation([assistant]);
            if (streamId === StreamId.current) {
                StreamId.current = '';
                // setStreaming(false);
                const uuid = uuidv4();
                setDocId(uuid);
                const id = uuid;
                DocumentId.current = uuid;
                const user = JSON.parse(localStorage.getItem('user') || '');
                try {
                    socket?.emit('store-document', {
                        id: uuid,
                        title: promptSent,
                        value: assistant.content,
                        owner_id: user?.id,
                    });
                } catch (error) {
                    socket?.emit('store-document', {
                        id: uuid,
                        title: promptSent,
                        value: assistant.content,
                    });
                }
                socket.emit('get-documents', user?.id);
            }
        });

        return () => {
            socket.off('message');
        };
    }, [socket]);

    const handleStreamEnd = async (prompt: string, data: ChatType) => {
        const dataToSave: ChatType[] = [
            {
                role: 'user',
                content: prompt,
            },
            data,
        ];

        await handleAddHistory(dataToSave, pageHistory);
        getPageHistory();
    };

    useEffect(() => {
        if (!isTyping && response != '') {
            handleStreamEnd(promptSent, streamEndMessage);
        }

        if (canNavigate && response != '') {
            openEditor({
                response,
                pageId: docId,
                promptSent: promptSent,
                promptType: basePrompt,
                navigate,
                pageSocket: socket,
            });
        }
    }, [response, canNavigate, isTyping, socket]);

    const handlePDF = async () => {
        let data = convertStringToDelta(response);
        const blob = await pdfExporter.generatePdf(data);
        const current_time = moment().format('YYYY-MM-DD HH:mm:ss');
        saveAs(blob as Blob, `gmind_document-${current_time}.pdf`);
    };
    const handleWord = async () => {
        let dataToDownload = response;
        const responseData = await axios.post(
            'https://api-v2.gmind.ai/v1/document/markdown-to-doc',
            { content: dataToDownload },
            {},
        );
        const { data: d } = responseData;
        if (d?.statusCode === 1) {
            const link = document.createElement('a');
            link.href = `https://api-v2.gmind.ai/${d?.url}`;
            const current_time = moment().format('YYYY-MM-DD HH:mm:ss');

            link.download = `gmind_document-${current_time}.docx`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            toast.success('Download successful');
        }
    };

    const handleMessageClick = () => {
        setDialogVisible(true);
    };
    const handleLikeClick = () => {};
    const handleDisLikeClick = () => {};

    useEffect(() => {
        if (window.innerWidth >= 820) {
            setIsMobile(false);
        }
    }, [window.innerWidth]);

    const regenerate = () => {
        onGenerateHandler(promptSent);
        setGenerate(true);
        setGenerating(true);
        setIsTyping(true);
        const timeoutId = setTimeout(() => {
            setGenerating(false);
        }, 3000);
    };

    const onFinish = async (promptMessage: string) => {
        console.log(promptMessage);
        setMessage(promptMessage);
        console.log(message);
        setIsTyping(true);
        setPromptSent(promptMessage);
        await onGenerateHandler(promptMessage);
        setGenerate(true);
        setGenerating(true);
        const timeoutId = setTimeout(() => {
            setGenerating(false);
        }, 3000);
    };

    const chatWithAI = async () => {
        const promptMessage = newPromptSent;
        setNewPromptSent(promptMessage);
        setPromptSent(promptMessage);

        await onGenerateHandler(promptMessage);
        setGenerate(true);
        setGenerating(true);
        const timeoutId = setTimeout(() => {
            setGenerating(false);
        }, 3000);
    };

    const handleSaveComment = (comment: any) => {
        console.log('Saved comment:', comment);
        setDialogVisible(false);
    };

    const onGenerateHandler = async (message: string, promptSelected?: string) => {
        if (!message) {
            toast.error("Input can't be empty");
            return;
        }
        toast.info('Please sit tight, your beautiful content is on its way.');
        // setIsTyping(true);
        let msgs = chats;
        msgs = { role: 'user', content: message };
        // console.log(msgs);
        // return;
        setChats(msgs);
        setNewPromptSent('');
        setResponse('');

        console.log(msgs);

        // let base = promptSelected ? promptSelected : letter ? prompts.letterWritter : prompts.content2(topic);
        // setBasePrompt(base);

        try {
            socket?.emit('data', {
                data: {
                    messages: [
                        {
                            role: 'system',
                            //   content: `You are G-Mind. You can help with all educational or academic questions or tasks`,
                            content: getPromptTypeBasedOnPage(),
                            type: 'gpt-4',
                        },
                        msgs,
                    ],
                },
            });
        } catch (error) {
            //   setIsTyping(false);
        } finally {
            //   setIsTyping(false);
        }
    };

    const setClickedHistory = useCallback(
        (id: string) => {
            let filterHistory: any = histories
                .flatMap((historyFormat: any) => historyFormat.histories)
                .filter((history: any) => {
                    const chatIdObj = history.find((h: any) => h.id === id);
                    return chatIdObj !== undefined;
                })
                .flat();

            // historyId.current = filterHistory.find((h: any) => h.chat_id)?.chat_id;
            filterHistory = filterHistory.filter((h: any) => h?.role && h?.content);
            const userPrompt = filterHistory.find((element: any) => element.role == 'user');
            const assistantResponse = filterHistory.find((element: any) => element.role == 'assistant');
            setPromptSent(userPrompt.content);
            setResponse(assistantResponse.content);
            setShowingHistory(true);
        },
        [histories, setPromptSent, setResponse, setShowingHistory],
    );
    const handleDeleteContentHistory = useCallback(
        async (id: string) => {
            await handleDeleteHistory(id, pageHistory);
            await getPageHistory();
            toast.success('Chat deleted successfully');
        },
        [getPageHistory],
    );

    const clearAllContentHistory = useCallback(async () => {
        await handleDeleteAllWithTypeHistory(pageHistory, historyType);
        await getPageHistory();
        toast.success('Chat cleared successfully');
    }, [getPageHistory, historyType]);

    const getPromptTypeBasedOnPage = () => {
        let prompt = prompts.chat;

        if (currentPage == 1) {
            prompt = prompts.copyWritingTool;
        } else if (currentPage == 2) {
            prompt = prompts.campaignAdGenerator;
        } else if (currentPage == 3) {
            prompt = prompts.policyWriter;
        } else if (currentPage == 4) {
            prompt = prompts.jobDescriptionGenerator;
        } else if (currentPage == 5) {
            prompt = prompts.projectReportGenerator;
        } else if (currentPage == 7) {
            prompt = prompts.socialContent;
        } else if (currentPage == 8) {
            prompt = prompts.textTranslator;
        } else if (currentPage == 9) {
            prompt = prompts.textSummarizer;
        }

        return prompt;
    };

    useEffect(() => {
        setHistory(histories);
        setShowRightComponent(false);

        setShowTop(true);
        setShowSidebar(true)
        setMobileNavStyle(!isMobile ? { position: 'fixed' } : {});

        setShowHistory(!isMobile);
        setShowInput(false);
        setShowPagePath(false);
        setCanNavigateHome(true);
        setShowHistoryOptions(page != '');
    }, [isMobile, page]);

    return (
        <div ref={bottomRef} className="h-full overflow-auto pt-2">
            {!generate && !showingHistory ? (
                <NewGenerateLetter
                    onGenerate={(prompt) => {
                        onFinish(prompt);
                    }}
                    setTopic={(e) => setTopic(e)}
                    setPageName={(e) => setPage(e)}
                    setShowSidebar={(e) => setSidebarVisible(e)}
                    setPageNumber={(i) => {
                        setCurrentPage(i);
                    }}
                />
            ) : (
                <div
                    className="w-full flex flex-col gap-0  bg-white dark:bg-[#212121]"
                >
                    <div className="w-full flex flex-row gap-0 ">
                        <div className="w-full flex flex-col gap-0  px-10 py-10">
                            <div className="mt-1">
                                <div className="flex flex-row">
                                    <BackButton
                                        onclick={() => {
                                            if (showingHistory) {
                                                setShowingHistory(false);
                                                setSidebarVisible(true);
                                                if (generate) {
                                                    setGenerate(false);
                                                }
                                                return;
                                            }
                                            setGenerate(false);
                                            console.log('hdjdd');
                                        }}
                                    />
                                    {<ContentTypingComponent isTyping={isTyping} />}
                                </div>

                                {showingHistory && (
                                    <div className="w-full flex flex-row gap-0 bg-custom-opacity px-10 py-10 overflow-y-auto">
                                        <div
                                            style={{
                                                height: '70px',
                                                backgroundColor: 'rgba(238, 238, 228, 0.75)',
                                                fontFamily: 'Inter',
                                                fontSize: '0.8125rem',
                                                fontWeight: '400',
                                            }}
                                            className="w-full flex flex-row gap-10 bg-white "
                                        >
                                            <img src={Profile} alt="profile" />
                                            <span>{promptSent}</span>
                                        </div>
                                    </div>
                                )}

                                {generating ? (
                                    <LoadingComponent isMobile={false} width={''} />
                                ) : (
                                    <div>
                                        <ResponseTextbox
                                            content={<MarkdownDesign className="">{response}</MarkdownDesign>}
                                            options={[]}
                                            disLikeClick={handleDisLikeClick}
                                            messageClick={handleMessageClick}
                                            likeClick={handleLikeClick}
                                            regenerateClick={regenerate}
                                            profileClick={() => {}}
                                        />
                                    </div>
                                )}
                            </div>
                            {/* <div className="sticky bottom-0 bg-custom-opacity">
                                <Input
                                    type="text"
                                    onChange={(e) => setNewPromptSent(e.target.value)}
                                    value={newPromptSent}
                                    placeholder="Describe what you want"
                                    style={{
                                        height: '50px',
                                        borderRadius: '16px',
                                        border: '1px solid lightgrey',
                                        marginTop: '17px',
                                    }}
                                    suffix={
                                        <svg
                                            onClick={() => chatWithAI()}
                                            width="36"
                                            height="36"
                                            viewBox="0 0 36 36"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M15 18L31.5 18"
                                                stroke="#E55109"
                                                stroke-width="1.5"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                            />
                                            <path
                                                d="M31.5004 18.0004L13.1254 26.6254C12.9116 26.7049 12.6711 26.6524 12.5098 26.4911C12.3485 26.3298 12.296 26.0893 12.3754 25.8754L15.0004 18.0004L12.3754 10.1254C12.296 9.91158 12.3485 9.67107 12.5098 9.50977C12.6711 9.34846 12.9116 9.29598 13.1254 9.37542L31.5004 18.0004"
                                                stroke="#E55109"
                                                stroke-width="1.5"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                            />
                                        </svg>
                                    }
                                />
                                <div className="advise">
                                    Gmind can make mistakes. It's advisable to verify crucial information.
                                </div>
                            </div> */}

                            <ReusableDialog
                                visible={dialogVisible}
                                title="Add Comment"
                                inputPlaceholder="Enter your comment"
                                saveLabel="Save"
                                onCancel={() => setDialogVisible(false)}
                                onSave={handleSaveComment}
                            />
                            {showShareModal && <Share url={UrlRef.current} onClose={() => setShowShareModal(false)} />}
                            {showDownload && (
                                <Download
                                    handlePDF={handlePDF}
                                    handleWord={handleWord}
                                    url={UrlRef.current}
                                    onClose={() => setShowDownload(false)}
                                />
                            )}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default BusinessResources;
