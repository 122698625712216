import create from 'zustand';

export interface Slide {
    title: string;
    content: string;
    image: string;
}

interface PowerPointState {
    slidesDescription: string;
    pages: number;
    background: string;
    contentSource: '' | 'ai' | 'manual';
    slides: Slide[];
    setSlidesDescription: (description: string) => void;
    setPages: (pages: number) => void;
    setContentSource: (source: '' | 'ai' | 'manual') => void;
    addSlide: (slide: Slide) => void;
    setSlides: (slide: Slide[]) => void;
    updateSlide: (index: number, slide: Slide) => void;
    removeSlide: (index: number) => void;
    setBackground: (url: string) => void;
}

const usePowerPointStore = create<PowerPointState>((set) => ({
    slidesDescription: '',
    pages: 5,
    contentSource: 'ai',
    slides: [],
    background: '',
    setSlidesDescription: (description) => set({ slidesDescription: description }),
    setSlides: (slides: Slide[]) => set({ slides }),
    setPages: (pages) => set({ pages }),
    setContentSource: (source) => set({ contentSource: source }),
    setBackground: (url) => set({ background: url }),
    addSlide: (slide) => set((state) => ({ slides: [...state.slides, slide] })),
    updateSlide: (index, slide) =>
        set((state) => {
            const updatedSlides = [...state.slides];
            updatedSlides[index] = slide;
            return { slides: updatedSlides };
        }),
    removeSlide: (index) =>
        set((state) => ({
            slides: state.slides.filter((_, i) => i !== index),
        })),
}));

export default usePowerPointStore;

export const sampleData: Slide[] = [
    {
        title: 'Introduction to JavaScript',
        content: 'JavaScript is a versatile programming language used primarily for web development.',
        image: 'https://example.com/images/javascript_intro.png',
    },
    {
        title: 'Understanding Variables',
        content: 'Variables in JavaScript are used to store data values. They are declared using var, let, or const.',
        image: 'https://example.com/images/variables.png',
    },
    {
        title: 'Functions and Scope',
        content:
            'Functions are blocks of code designed to perform a particular task. Scope determines the accessibility of variables.',
        image: 'https://example.com/images/functions_scope.png',
    },
    {
        title: 'Introduction to HTML',
        content: 'HTML stands for HyperText Markup Language. It is the standard language for creating web pages.',
        image: 'https://example.com/images/html_intro.png',
    },
    {
        title: 'CSS Basics',
        content:
            'CSS stands for Cascading Style Sheets. It describes how HTML elements are to be displayed on screen, paper, or in other media.',
        image: 'https://example.com/images/css_basics.png',
    },
];
