import ellipsePointer from '@assets/socials/ellipse_pointer.svg';
import userEngagement from '@assets/user-engagement.svg';
import efficacy from '@assets/efficacy.svg';
import thinking from '@assets/thinking.svg';
import readingBook from '@assets/reading-book.svg';
import { icons } from 'antd/es/image/PreviewGroup';

interface serviceType {
    head: string;
    description: string;
    icon: string;
}

const Services = () => {
    const servicesData = [
        {
            head: 'Enhanced Engagement',
            desscription:
                'GMind AI provides intriguing and diverse content, thus fostering an environment of sustained interest and involvement among learners.',
            icons: userEngagement,
        },
        {
            head: 'Increased Efficiency',
            desscription:
                'Automation of lesson plan production and content generation saves significant time, enabling users to concentrate on other crucial tasks.',
            icons: efficacy,
        },
        {
            head: 'Promotion of Higher-Order Thinking Skills',
            desscription:
                'Interactive Q&A fosters higher-order thinking skills like analytical thinking, problem-solving, and decision making.',
            icons: thinking,
        },
        {
            head: 'Personalized Learning',
            desscription:
                'Users have the liberty to tailor their learning journey based on their individual pace, strengths, and areas of interest.',
            icons: readingBook,
        },
    ];

    return (
        <div className="flex flex-col gap-y-5 w-full mt-20 mb-5">
            <div className="flex flex-col items-center justify-center">
                <p className="flex hidden md:block  text-center text-[1.5rem] font-medium text-[var(--gmind-black)]">
                    Why GMind?
                </p>
                <p className="flex hidden md:block  text-center text-[0.8rem] font-normal text-[var(--gmind-light-black) w-[668px]">
                    GMind AI operates on an advanced machine learning algorithm that enhances its functionality and adaptability. Essentially, it learns from the users’ preferences and patterns over time, delivering more personalized and efficient results
                </p>
            </div>
            <p className="flex md:hidden text-center text-[1.5rem] font-medium text-[var(--gmind-black)]">
                See how G-Mind Ai increases users’ productivity
            </p>
            <p className="flex md:hidden text-center text-[0.8rem] font-normal text-[var(--gmind-light-black)]">
                Personalized AI learning with interactive modules, community support, 24/7 assistance, certifications,
                career exploration, and progress tracking.
            </p>
            <div className="w-full flex flex-col md:flex-row flex-wrap gap-x-5 gap-y-3 items-center justify-center">
                {servicesData.map((service, index) => (
                    <div key={index} className="flex gap-x-3 md:gap-x-10 rounded-[1.875rem] md:rounded-[2.75rem] w-full md:w-[30rem] h-auto md:h-[15rem] items-center py-10 pl-5 md:pl-10 justify-center bg-[var(--gmind-playground-grey)]">
                        <div className="flex flex-col gap-y-5">
                            <div className="flex items-center gap-x-5">
                                <img src={ellipsePointer} alt="ellipsePointer" />
                                <div className="flex flex-col gap-y-3">
                                    <p className="w-[8.75rem] md:w-[13rem] text-[1.25rem] font-medium text-[var(--gmind-black)]">
                                        {service.head}
                                    </p>
                                    <p className="w-[8.75rem] md:w-[13rem] font-normal text-[0.8rem] text-[var(--gmind-rating)]">
                                        {service.desscription}
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="w-[9rem] md:w-[13rem] h-[9rem] md:h-[10rem] rounded-tl-[2rem] md:rounded-tl-[3rem] rounded-bl-[2rem] md:rounded-bl-[3rem] flex flex-col items-center justify-center bg-[var(--gmind-orange8)]">
                            <img className='w-[3rem] md:w-auto h-[3rem] md:h-auto' src={service.icons} alt="engagement" />
                        </div>
                    </div>
                ))}

              
            </div>
        </div>
    );
};

export default Services;
