import logo from '@assets/gmind_white.svg';
import facebook from '@socials/Facebook.svg';
import youtube from '@socials/Youtube.svg';
import instagram from '@socials/Instragram.svg';
import linkedin from '@socials/LinkedIn.svg';
import mail from '@icons/mail.svg';
import call from '@icons/Call.svg';
import location from '@icons/Location.svg';
import { Link } from 'react-router-dom';
import { GetStartedButton } from '@components/GetStartedButton';

type socialsType = {
    icon: string;
    link: string;
};

type contactType = {
    icon: string;
    onClick: () => void;
    details: string;
};

const Footer = () => {
    const contactSection: contactType[] = [
        {
            icon: mail,
            onClick: () => {},
            details: 'info@gmind.AI',
        },
    ];

    const socials: socialsType[] = [
        {
            icon: facebook,
            link: 'https://www.facebook.com',
        },
        {
            icon: youtube,
            link: 'https://youtu.be/rWwjRuLm92w?si=fNGD_T0KtoxG2LQu ',
        },
        {
            icon: instagram,
            link: 'https://www.instagram.com',
        },
        {
            icon: linkedin,
            link: 'https://www.linkedin.com',
        },
    ];

    return (
        <footer className="w-full flex flex-col md:flex-row items-center md:items-start justify-between px-10 md:px-20 py-[4rem] bg-[var(--gmind-footer)]">
            <div className="flex flex-col gap-y-5 w-full lg:w-[40%]">
                <div className="flex flex-col gap-y-5 lg:gap-y-2">
                    <img src={logo} loading='lazy' alt="logo" className="self-center lg:self-start w-[10rem] h-[3rem]" />
                    <h2 className='self-center lg:self-start text-center lg:text-start mx-3 font-normal text-[0.8rem] lg:text-[0.9rem] text-white'>GMind AI, standing for Gotocourse Mind AI, is a state-of-the-art educational technology developed by Gotocourse. This AI model is designed to create robust and comprehensive educational content for teachers and schools.</h2>
                </div>
                

                <GetStartedButton
                    onclick={() => {}}
                    className="flex lg:hidden self-center h-[3rem] w-[8.75rem]"
                    style={{
                        padding: '1rem 2rem',
                    }}
                />

                <div className="flex flex-col gap-y-10 lg:hidden w-full">
                    <div className="self-center flex gap-x-3">
                        {socials.map((social, index) => (
                            <Link to={social.link} key={index}>
                                <img src={social.icon} alt="social" className="w-[2rem] h-[2.25rem]" />
                            </Link>
                        ))}
                    </div>

                    <h2 className="self-center font-normal text-white text-[0.8rem]">Get Support</h2>

                    {contactSection.map((contact, index) => (
                        <div onClick={contact.onClick} key={index} className="self-center flex gap-x-3 items-center">
                            <img src={contact.icon} alt="social" className="w-[1.5rem] h-[1.5rem]" />
                            <p className="font-normal text-[0.8rem] text-white">{contact.details}</p>
                        </div>
                    ))}
                </div>

                <p className="mt-10 self-center lg:self-start text-white text-[0.8rem] lg:font-normal">
                    Copyright © 2023 GotoCourse. All rights reserved.
                </p>

                <div className="hidden lg:flex gap-x-3 items-center " style={{marginRight: '48px'}}>
                    {socials.map((social, index) => (
                        <Link to={social.link} key={index}>
                            <img src={social.icon} alt="social" className="w-[2rem] h-[2.25rem]" />
                        </Link>
                    ))}
                </div>
            </div>

            <div className="w-[50%] hidden lg:flex gap-x-5">
                <div className='text-white flex flex-col gap-8 h-full -translate-x-10'>
                    <Link to={"/"}>Home</Link>
                    <Link to={"/"}>FAQs</Link>
                    <Link to={"/"}>Features</Link>
                </div>
                <div className='text-white flex flex-col gap-8 h-full -translate-x-10'>
                    <Link to={"/cookies"}>Cookie Policy</Link>
                    <Link to={"/terms-of-service"}>Terms of Service</Link>
                    <Link to={"/privacy-policy"}>Privacy Policy</Link>
                </div>

                <div className="flex flex-col gap-y-5">
                    <h2 className="font-bold text-white text-[1.25rem]">Get Support</h2>

                    {contactSection.map((contact, index) => (
                        <div onClick={contact.onClick} key={index} className="flex gap-x-3 items-center">
                            <img src={contact.icon} alt="social" className="w-[1.5rem] h-[1.5rem]" />
                            <p className="font-normal text-white">{contact.details}</p>
                        </div>
                    ))}
                </div>

                <GetStartedButton onclick={() => {}} className="hidden lg:flex h-[3rem] w-[8rem]" />
            </div>
        </footer>
    );
};

export default Footer;
