import useFormStore from '@src/providers/formStore';
import usePowerPointStore from '@src/providers/store';
import React, { useEffect, useState } from 'react';
import FirstForm from './component/Forms/first-form';
import DashboardFrame from '@src/components/DashboardFrame';
import { ChatType, getHistory, handleDeleteHistory } from '../../endpoints';
import SecondForm from './component/Forms/second-form';
import ThirdForm from './component/Forms/third-form';
import PptViewer from './component/Forms/preview-slide';
import { toast } from 'react-toastify';
import { useOutletContext } from 'react-router';

type Props = {};

function SlideMaker({}: Props) {
    const { currentStep, nextStep, previousStep } = useFormStore();
    const { ...formState } = usePowerPointStore();
    const [isMobile, setIsMobile] = useState(true);
    const [histories, setHistories] = useState([]);
  
    const {
        setShowMobileHistory,
        setShowTop,
        setShowSidebar,
        setRightComponentName,
        setRightComponentPlaceholder,
        setRightComponentData,
        setHistory,
        setMiddleClass,
        setMobileNavStyle,
        selectedHistory,
        setShowPagePath,
        setTopExtraData,
        setCloseIdValue,
        setShowHistoryOption,
        setGmindTyping,
        setCanNavigateHome,
        setShowExpiredModal,
        setShowCompleteProfileModal,
        setIsAffiliate,
        setMenu,
        setShowInput, 
        setShowHistoryOptions,
        setRightComponentClick,
        setOnDetailsFetched,
        setOnDeleteAllHistory,
        setOnDeleteHistory,
        setOnClickPage,
        setSelectedHistory,
        setValue,
        setShowRightComponent,
        setSchoolAdminPageName,
        setShowSchoolHeader,
        setShowHistory,
        getHistory,
        setChatList,
        chatList,
    } = useOutletContext<any>();

    const pageHistory = 'slide';

    useEffect(() => {
        if (window.innerWidth >= 820) {
            setIsMobile(false);
        }
    }, [window.innerWidth]);
    const getPageHistory = () => {
        getHistory(pageHistory).then((response) => {
            if (response?.statusCode === 1) {
                const data = response?.data;
                const filtered = data?.map((c: any) => {
                    return [...c.conversation, { id: c._id, date: c.createdAt }];
                });
                setHistories(filtered);
            }
        });
    };
 
    const setClickedHistory = (id: string) => {
        const filterHistory = histories
            .filter((h: any) => {
                const { id: history_id } = h[h.length - 1];
                return String(history_id) === String(id);
            })
            .flat()
            .filter((h: any) => !h?.id);
        setChatList(filterHistory);
    };
    const fileUrl = 'https://res.cloudinary.com/dobksjz6x/raw/upload/v1717350633/GmindGeneratedSlides_3_vbb71g.pptx';

    const renderStepContent = () => {
        switch (currentStep) {
            case 0:
                return <FirstForm />;
            case 1:
                return <SecondForm />;
            case 2:
                return <ThirdForm />;
            default:
                return <div>Unknown Step</div>;
        }
    };



    return (
      
            <div className="w-full px-10">{renderStepContent()}</div>
        
    );
}

export default SlideMaker;
