import FeaturesImg from '@icons/features_page.svg';
import { GetStartedButton } from '@src/components/GetStartedButton';
import { useNavigate } from 'react-router';
import curve from "@assets/curve.svg";
import learningGmind from "@assets/learning_gmind.svg";

const FeaturesAbout = ({isLoggedIn}: {isLoggedIn?: boolean;}) => {
    const navigate = useNavigate();
    
    return (
        <div className="w-full flex justify-between h-full">
            <div className="w-full md:w-[45%] h-full flex flex-col gap-y-5 py-5 md:py-12">
                <h3 className="font-medium text-[2rem] md:text-[2.8rem] text-[var(--gmind-black)]">
                    Reimagined <span className="text-[var(--gmind-orange)]">contents creation</span> with AI.
                </h3>
                <h2 className="font-normal text-[1rem] text-[var(--gmind-light-black)]">
                    This shift is revolutionizing the future of learning and resource generation, enabling the development of personalized, adaptive, and intelligent content that empowers individuals to learn more effectively and access valuable resources more efficiently
                </h2> 

                <GetStartedButton isLoggedIn={isLoggedIn} className="hidden md:flex w-[10rem]" onclick={() => {
                    if ( isLoggedIn ) {
                        navigate("/dashboard/chat");
                    }else {
                    navigate("/signup"); 
                    } 
                }}  />

                <div className="self-end hidden md:flex gap-x-3">
                    <img src={curve} alt="curve" />
                    <img src={learningGmind} alt="learning gmind" />
                </div>
            </div>
            <div className="hidden md:flex md:w-[55%] h-full">
                <img className='w-full h-full' src={FeaturesImg} alt="features img" />
            </div>
        </div>
    );
}


export default FeaturesAbout;