import React, { useEffect, useState, useRef } from 'react';
import "./styles/index.scss";
import OtpInput from 'react-otp-input';
import Foot from '@icons/Footer.svg';
import { useLocation, useNavigate } from 'react-router-dom';
import Loading from '@src/components/Loading';
import useEndpoint from '../auth/auth_endpoints';
import { toast } from 'react-toastify';

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const OtpScreen = () => {
    const [otp, setOtp] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const query = useQuery();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const verifyWithLink = async (token: string, user: string) => {
      try {
        let response = await useEndpoint().verifyEmail({token, userId: user});
        if ( response.statusCode == 1 ) {
          setLoading(false);
          window.location.pathname = "/dashboard/chat";

        }else{
          setLoading(false);
          toast.error(response.message || 'Something went wrong' );
        }
        
      } catch (error: any) {
        setLoading(false);
        toast.error(error?.message || 'Something went wrong' );
        return;
      }
     
    }

    const verifyWithOtp = async () => {
      if ( !otp ) {
        toast.error('Please enter OTP');
        return;
      }

      if ( otp.length != 6 ) {
        toast.error('Please enter valid OTP');
        return;
      }

      try {
        let response = await useEndpoint().verifyEmail({otp, email});
        if ( response.statusCode == 1 ) {
          setLoading(false);
          window.location.pathname = "/dashboard/chat";

        }else{
          setLoading(false);
          toast.error(response.message || 'Something went wrong' );
        }
        
      } catch (error: any) {
        setLoading(false);
        toast.error(error?.message || 'Something went wrong' );
        return;
      }
     
    }


    useEffect(() => {
        const token = query.get('token') || '';
        const user = query.get('user') || '';
        const emailExtracted = query.get('email') || '';

        setEmail(emailExtracted);

        navigate({
          pathname: window.location.pathname,
          search: ''
        }, { replace: true });
    
        if (token && user) {
          setLoading(true);
          verifyWithLink(token, user);
        }
    }, []);
  
    return (
      loading ? (
        <Loading />
      ): (
        <div className='pt-[8%] h-full w-full flex flex-col gap-y-3 items-center justify-between'>
            <div className='mb-10 flex flex-col gap-y-3 items-center justify-between'>
              <h1 className='text-[1.3rem] font-[Inter] font-medium'>Please enter the One-Time Password to verify your account</h1>
              <OtpInput
                value={otp}
                onChange={(value) => setOtp(value)}
                numInputs={6}
                inputStyle="otp-input"
                renderInput={(props) => <input {...props} className="otp-input" />}
              />
              <button onClick={() => {verifyWithOtp()}} className={`mt-10 w-[60%] flex flex-row items-center justify-center rounded-[0.6rem] bg-[var(--gmind-orange)] w-[10rem] text-white font-normal text-center text-[0.8rem] px-5 py-3`}>
                  Proceed
              </button>
              <h1 className='mt-5 text-[1.2rem] font-[Inter] font-medium'>Didn't get your code? <span className='text-[var(--gmind-orange)]'>Resend</span></h1>

            </div>
            <img alt="foot" src={Foot} className="self-end w-full" />
        </div>
      )
    );
}

export default OtpScreen;
