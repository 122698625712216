import { Col, Form, Input, Row } from 'antd';
import { useEffect, useState } from 'react';
import './style.scss';
import AIGenerate from '@icons/generate.svg';
import { toast } from 'react-toastify';
import { CircularProgress } from '@mui/material';
import { BASE_URL } from '@src/utils/constants';
import { io, Socket } from 'socket.io-client';
import { onGenerateWithAI } from '../../curriculum/functions';

const { TextArea } = Input;
interface LoadingStates {
    procedures: boolean;
    considerations: boolean;
    review: boolean;
    related: boolean;
    references: boolean;
    version: boolean;
    definition: boolean;
}

interface PolicyWriterProps {
    onGenerate: (e: string) => void;
    setTopic: (e: string) => void;
}

const CustomTextAreaWithButton = ({
    placeholder,
    uniqueKey,
    loadingStates,
    setLoadingStates,
    value,
    onChange,
    onClickButton,
}: {
    placeholder: string;
    uniqueKey: keyof LoadingStates;
    loadingStates: LoadingStates;
    setLoadingStates: React.Dispatch<React.SetStateAction<LoadingStates>>;
    value?: string; // Optional value prop
    onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void; // Optional onChange prop
    onClickButton: () => boolean;
}) => {
    const handleButtonClick = () => {
        let response = onClickButton();
        if ( response ){
            setLoadingStates((prevState) => ({
                ...prevState,
                [uniqueKey]: true,
            }));
        }

    };

    return (
        <div className="relative">
            <TextArea
                autoSize={{ minRows: 4}}
                placeholder={placeholder}
                className="p-2 border border-gray-500 focus:outline-none"
                style={{ outline: 'none', boxShadow: 'none' }}
                value={value}
                onChange={onChange}
            />
            {!loadingStates[uniqueKey] ? (
                <div
                    onClick={handleButtonClick}
                    className="cursor-pointer shadow-md rounded-[1rem] absolute top-[55px] right-[10px] py-[0.3rem] px-[1rem] bg-white text-normal text-[0.75rem] text-[var(--gmind-black)] flex gap-x-2"
                >
                    <img src={AIGenerate} alt="" />
                    <span>Use Gmind AI</span>
                </div>
            ) : (
                <div className="absolute top-[65px] right-[20px] ">
                    <CircularProgress size={15} className="w-1 h-1" />
                </div>
            )}
        </div>
    );
};

const PolicyWriter = ({ onGenerate, setTopic }: PolicyWriterProps) => {
    const [form] = Form.useForm();
    const [isPageOne, setIsPageOne] = useState(true);
    const [pageOneValues, setPageOneValues] = useState<{[key: string]: string}>({
        'objective': '',
        'scope': '',
        'statement': '',
        'responsibilities': '',
        'compliance': '',
        'violations': '',
        'exceptions': '',
        'approval': '',
    });
    const [sockets, setSockets] = useState<{ [key: string]: Socket | null}>({});
    const baseurl = BASE_URL;
    const socketKey = ['considerations', 'review', 'procedures', 'related', 'references', 'version', 'definition']
    const [loadingStates, setLoadingStates] = useState<LoadingStates>({
        procedures: false,
        considerations: false,
        review: false,
        related: false,
        references: false,
        version: false,
        definition: false,
    });


    useEffect(() => {
        const newSockets: { [key: string]: Socket } = {};
        socketKey.forEach(item => {
            newSockets[item] = io(baseurl.slice(0, -2));
        });
        setSockets(newSockets);

        return () => {
            Object.values(newSockets).forEach(socket => {
                socket.close();
            });
        };
    }, []);

    useEffect(() => {
        socketKey.forEach(item => {
            const socket = sockets[item];
            if (!socket) return;

            const handleData = (data: string) => {
                let previousValue = form.getFieldValue(item);
                form.setFieldValue( item, previousValue + data);
            };

            const handleStreamEnd = () => {
                setLoadingStates((prevState) => ({
                    ...prevState,
                    [item]: false,
                }));
            };

            socket.on('data', handleData);
            socket.on('stream_end', handleStreamEnd);
            return () => {
                socket.off('data', handleData);
                socket.off('stream_end', handleData);
            };
        });
    }, [sockets]);


    const onFinish = (values: any) => {
        const {
            objective,
            scope,
            statement,
            responsibilities,
            compliance,
            violations,
            exceptions,
            approval,
        } = pageOneValues;

        const {
            procedures,
            considerations,
            review,
            related,
            references,
            date,
            version,
            definition,
        } = values;

        if (
            !objective ||
            !scope ||
            !statement ||
            !responsibilities ||
            !procedures ||
            !compliance ||
            !violations ||
            !exceptions ||
            !approval ||
            !exceptions ||
            !considerations ||
            !review ||
            !related ||
            !references ||
            !date ||
            !version ||
            !definition 
        ) {
            toast.error('Please fill all the required fields before generating.');
            return;
        }

        let informationData = `
             Objective: ${objective}
            \nScope: ${scope}
            \nPolicy Statement: ${statement}
            \nResponsibilities: ${responsibilities}
            \nCompliance: ${compliance}
            \nReporting Violations: ${violations}
            \nExceptions: ${exceptions}
            \nApproval: ${approval}
            \nProcedures: ${procedures}
            \nEthical Considerations: ${considerations}
            \nReview and Updates: ${review}
            \nRelated Policies: ${related}
            \nReferences: ${references}
            \nEffective Date: ${date}
            \nVersion History: ${version}
            \nDefinitions: ${date}
        `;

        const promptMessage = `Generate a well-structured and professionally written policy with the following details: ${informationData}`;
        onGenerate(promptMessage);
    };

    return (
        <Form
            layout="vertical"
            form={form}
            onFinish={onFinish}
            initialValues={{
                policyTitle: '',
                purposeAndScope: '',
                definitions: '',
                policyStatement: '',
                procedures: '',
                rolesResponsibilities: '',
                complianceEnforcement: '',
                conclusion: '',
                reviewRevision: '',
                relatedPolicies: '',
            }}
        >
            <h1 className="text-xl dark:text-white font-bold font-Poppins mb-[16px]">Policy Writer</h1>
            <p className="text-md  dark:text-gray-300 font-Poppins mb-[16px]">
                Create comprehensive and clear policy documents with the help of this tool.
            </p>

            {isPageOne && (
                <>
                    <div className="grid grid-col-1 md:grid-cols-2 gap-4">
                        <div>
                            <Form.Item
                                label="Purpose / Objective"
                                name="objective"
                                rules={[{required: true, message: 'Purpose / Objective is required' }]}
                            >
                                <TextArea autoSize={{ minRows: 2}}  placeholder=" Clearly state the specific intention or desired outcome of the policy (e.g., 'To ensure data privacy and security for all customer information')" />
                            </Form.Item>
                        </div>
                        <div>
                            <Form.Item
                                label="Scope"
                                name="scope"
                                rules={[{ required: true, message: 'Purpose and Scope are required' }]}
                            >
                                <TextArea autoSize={{ minRows: 2}} placeholder={`Define precisely which departments, roles, or processes the policy affects (e.g., "Applies to the IT and HR departments within the North American offices").`} />
                            </Form.Item>
                        </div>
                    </div>

                    <div className="grid grid-cols-1  md:grid-cols-2 gap-4">
                        <div>
                            <Form.Item
                                label="Policy Statement"
                                name="statement"
                                rules={[{ required: true, message: 'Policy Statement is required' }]}
                            >
                                <TextArea autoSize={{ minRows: 2}}  placeholder={`Provide a concise declaration of the policy’s main rules or principles (e.g., "Employees must secure all digital communications using encryption").`} />
                            </Form.Item>
                        </div>
                        <div>
                            <Form.Item
                                label="Responsibilities"
                                name="responsibilities"
                                rules={[{ required: true, message: 'Responsibilities are required' }]}
                            >
                                <TextArea autoSize={{ minRows: 2}} placeholder={`List specific roles or departments responsible for the implementation and monitoring of the policy (e.g., "The IT Manager is responsible for overseeing data backups").`} />
                            </Form.Item>
                        </div>
                    </div>

                    <div className="grid grid-cols-1  md:grid-cols-2 gap-4">
                        <div>
                            <Form.Item
                                label="Compliance"
                                name="compliance"
                                rules={[{ required: true, message: 'Compliance is required' }]}
                            >
                                <TextArea autoSize={{ minRows: 2}}  placeholder={`Specify how adherence to relevant laws and regulations is maintained (e.g., "Compliant with the General Data Protection Regulation (GDPR)").`} />
                            </Form.Item>
                        </div>
                        <div>
                            <Form.Item
                                label="Reporting Violations"
                                name="violations"
                                rules={[{ required: true, message: 'Reporting Violations are required' }]}
                            >
                                <TextArea autoSize={{ minRows: 2}} placeholder={` Describe the exact process and channels for reporting noncompliance (e.g., "Violations should be reported to the Compliance Officer via the confidential hotline").`} />
                            </Form.Item>
                        </div>
                    </div>

                    <div className="grid grid-cols-1  md:grid-cols-2 gap-4">
                        <div>
                            <Form.Item
                                label="Exceptions"
                                name="exceptions"
                                rules={[{ required: true, message: 'Exceptions is required' }]}
                            >
                                <TextArea autoSize={{ minRows: 2}}  placeholder={`Specify scenarios where deviations from the policy are permissible and who can authorize them (e.g., "Exceptions may be granted by the Risk Management Team for urgent business needs").`} />
                            </Form.Item>
                        </div>
                        <div>
                            <Form.Item
                                label="Approval"
                                name="approval"
                                rules={[{ required: true, message: 'Approval are required' }]}
                            >
                                <Input placeholder={`Record details of the approvers and the date of approval (e.g., "Approved by the Board of Directors on March 1, 2023").`} />
                            </Form.Item>
                        </div>
                    </div>

                    <Row gutter={16}>
                        <Col span={24}>
                            <button
                                onClick={() =>  form.validateFields().then(() => {
                                    setPageOneValues({
                                        'objective': form.getFieldValue('objective'),
                                        'scope': form.getFieldValue('scope'),
                                        'statement': form.getFieldValue('statement'),
                                        'responsibilities': form.getFieldValue('responsibilities'),
                                        'compliance': form.getFieldValue('compliance'),
                                        'violations': form.getFieldValue('violations'),
                                        'exceptions': form.getFieldValue('exceptions'),
                                        'approval': form.getFieldValue('approval'),
                                    })
                                    setIsPageOne(false);
                                }) }
                                className="w-full md:w-[8rem] bg-customOrange text-white rounded p-2"
                            >
                                Next
                            </button>
                        </Col>
                    </Row>
                </>
            )}

            {!isPageOne && (
                <>
                    <div className="grid grid-cols-1  md:grid-cols-2 gap-4">
                        <div>
                            <Form.Item
                                label="Procedures"
                                name="procedures"
                                rules={[{ required: true, message: 'Procedures are required' }]}
                            >
                                <CustomTextAreaWithButton
                                    placeholder="Detail the procedures to follow"
                                    uniqueKey="procedures"
                                    loadingStates={loadingStates}
                                    setLoadingStates={setLoadingStates}
                                    value={form.getFieldValue('procedures')} // Bind value to form field
                                    onChange={(e) => form.setFieldValue('procedures', e.target.value)} // Update form value on change
                                    onClickButton={() => {
                                        form.setFieldValue('procedures', '')
                                        const details = `
                                            Objective: ${pageOneValues['objective']}
                                            \nScope: ${pageOneValues['scope']}
                                            \nPolicy Statement: ${pageOneValues['statement']}
                                            \nResponsibilities: ${pageOneValues['responsibilities']}
                                            \nCompliance: ${pageOneValues['compliance']}
                                            \nReporting Violations: ${pageOneValues['violations']}
                                            \nExceptions: ${pageOneValues['exceptions']}
                                            \nApproval: ${pageOneValues['approval']}
                                        `;
                                        let prompt = `Generate a policy Procedures with not more than 500 characters for this policy with details ${details}.`;
                                        const socket = sockets["procedures"];
                                        onGenerateWithAI(socket, prompt);
                                        return true;
                                    }}
                                />
                            </Form.Item>
                        </div>
                        <div>
                            <Form.Item
                                label="Ethical Considerations"
                                name="considerations"
                                rules={[{ required: true, message: 'Ethical Considerations are required' }]}
                            >
                                <CustomTextAreaWithButton
                                    placeholder={`Highlight ethical standards or values the policy supports (e.g., "Ensures fair treatment and privacy for all users").`}
                                    uniqueKey="considerations"
                                    loadingStates={loadingStates}
                                    setLoadingStates={setLoadingStates}
                                    value={form.getFieldValue('considerations')} // Bind value to form field
                                    onChange={(e) => form.setFieldValue('considerations', e.target.value)} // Update form value on change
                                    onClickButton={() => {
                                        form.setFieldValue('rolesResponsibilities', '')
                                        const details = `
                                            Objective: ${pageOneValues['objective']}
                                            \nScope: ${pageOneValues['scope']}
                                            \nPolicy Statement: ${pageOneValues['statement']}
                                            \nResponsibilities: ${pageOneValues['responsibilities']}
                                            \nCompliance: ${pageOneValues['compliance']}
                                            \nReporting Violations: ${pageOneValues['violations']}
                                            \nExceptions: ${pageOneValues['exceptions']}
                                            \nApproval: ${pageOneValues['approval']}
                                        `;
                                        let prompt = `Generate a policy Ethical Considerations with not more than 500 characters for this policy with details ${details}.`;
                                        const socket = sockets["considerations"];
                                        onGenerateWithAI(socket, prompt);
                                        return true;
                                    }}
                                />
                            </Form.Item>
                        </div>
                    </div>

                    <div className="grid grid-cols-1  md:grid-cols-2 gap-4">
                        <div>
                            <Form.Item
                                label="Review and Updates"
                                name="review"
                                rules={[{ required: true, message: 'Review and Updates are required' }]}
                            >
                                <CustomTextAreaWithButton
                                    placeholder={`Indicate the frequency and criteria for reviewing the policy (e.g., "The policy will be reviewed annually or in response to relevant regulatory changes").`}
                                    uniqueKey="review"
                                    loadingStates={loadingStates}
                                    setLoadingStates={setLoadingStates}
                                    value={form.getFieldValue('review')} // Bind value to form field
                                    onChange={(e) => form.setFieldValue('review', e.target.value)} // Update form value on change
                                    onClickButton={() => {
                                        form.setFieldValue('review', '')
                                        const details = `
                                            Objective: ${pageOneValues['objective']}
                                            \nScope: ${pageOneValues['scope']}
                                            \nPolicy Statement: ${pageOneValues['statement']}
                                            \nResponsibilities: ${pageOneValues['responsibilities']}
                                            \nCompliance: ${pageOneValues['compliance']}
                                            \nReporting Violations: ${pageOneValues['violations']}
                                            \nExceptions: ${pageOneValues['exceptions']}
                                            \nApproval: ${pageOneValues['approval']}
                                        `;
                                        let prompt = `Generate a policy Ethical Considerations with not more than 500 characters for this policy with details ${details}.`;
                                        const socket = sockets["review"];
                                        onGenerateWithAI(socket, prompt);
                                        return true;
                                    }}
                                />
                            </Form.Item>
                        </div>
                        <div>
                            <Form.Item
                                label="Related Policies"
                                name="related"
                                rules={[{ required: true, message: 'Related Policies is required' }]}
                            >
                                <CustomTextAreaWithButton
                                    placeholder={`Identify and list other policies that align or interact with this policy (e.g., "See also: Data Retention Policy, Employee Conduct Policy").`}
                                    uniqueKey="related"
                                    loadingStates={loadingStates}
                                    setLoadingStates={setLoadingStates}
                                    value={form.getFieldValue('related')} // Bind value to form field
                                    onChange={(e) => form.setFieldValue('related', e.target.value)} // Update form value on change
                                    onClickButton={() => {
                                        form.setFieldValue('related', '')
                                        const details = `
                                            Objective: ${pageOneValues['objective']}
                                            \nScope: ${pageOneValues['scope']}
                                            \nPolicy Statement: ${pageOneValues['statement']}
                                            \nResponsibilities: ${pageOneValues['responsibilities']}
                                            \nCompliance: ${pageOneValues['compliance']}
                                            \nReporting Violations: ${pageOneValues['violations']}
                                            \nExceptions: ${pageOneValues['exceptions']}
                                            \nApproval: ${pageOneValues['approval']}
                                        `;
                                        let prompt = `Generate a policy Ethical Considerations with not more than 500 characters for this policy with details ${details}.`;
                                        const socket = sockets["related"];
                                        onGenerateWithAI(socket, prompt);
                                        return true;
                                    }}
                                />
                            </Form.Item>
                        </div>
                    </div>

                    <div className="grid grid-cols-1  md:grid-cols-2 gap-4">
                        <div>
                            <Form.Item
                                label="References"
                                name="references"
                                rules={[{ required: true, message: 'References are required' }]}
                            >
                                <CustomTextAreaWithButton
                                    placeholder={`Cite specific external documents, such as laws or industry standards, that the policy adheres to (e.g., "References ISO 27001 for information security management").`}
                                    uniqueKey="references"
                                    loadingStates={loadingStates}
                                    setLoadingStates={setLoadingStates}
                                    value={form.getFieldValue('references')} // Bind value to form field
                                    onChange={(e) => form.setFieldValue('references', e.target.value)} // Update form value on change
                                    onClickButton={() => {
                                        form.setFieldValue('references', '')
                                        const details = `
                                            Objective: ${pageOneValues['objective']}
                                            \nScope: ${pageOneValues['scope']}
                                            \nPolicy Statement: ${pageOneValues['statement']}
                                            \nResponsibilities: ${pageOneValues['responsibilities']}
                                            \nCompliance: ${pageOneValues['compliance']}
                                            \nReporting Violations: ${pageOneValues['violations']}
                                            \nExceptions: ${pageOneValues['exceptions']}
                                            \nApproval: ${pageOneValues['approval']}
                                        `;
                                        let prompt = `Generate a policy References with not more than 500 characters for this policy with details ${details}.`;
                                        const socket = sockets["references"];
                                        onGenerateWithAI(socket, prompt);
                                        return true;
                                    }}
                                />
                            </Form.Item>
                        </div>
                        <div>
                            <Form.Item
                                label="Effective Date"
                                name="date"
                                rules={[{ required: true, message: 'Effective Date is required' }]}
                            >
                                <Input placeholder={`State when the policy becomes active (e.g., "Effective as of April 1, 2023").`} />
                            </Form.Item>
                        </div>
                    </div>

                    <div className="grid grid-cols-1  md:grid-cols-2 gap-4">
                        <div>
                            <Form.Item 
                                label="Version History (Optional)" 
                                name="version"
                            >
                                <CustomTextAreaWithButton
                                    placeholder={`Document previous changes with dates and descriptions (e.g., "Version 2.0 – Updated procedures section on Jan 15, 2023").`}
                                    uniqueKey="version"
                                    loadingStates={loadingStates}
                                    setLoadingStates={setLoadingStates}
                                    value={form.getFieldValue('version')} // Bind value to form field
                                    onChange={(e) => form.setFieldValue('version', e.target.value)} // Update form value on change
                                    onClickButton={() => {
                                        form.setFieldValue('version', '')
                                        const details = `
                                            Objective: ${pageOneValues['objective']}
                                            \nScope: ${pageOneValues['scope']}
                                            \nPolicy Statement: ${pageOneValues['statement']}
                                            \nResponsibilities: ${pageOneValues['responsibilities']}
                                            \nCompliance: ${pageOneValues['compliance']}
                                            \nReporting Violations: ${pageOneValues['violations']}
                                            \nExceptions: ${pageOneValues['exceptions']}
                                            \nApproval: ${pageOneValues['approval']}
                                        `;
                                        let prompt = `Generate a policy Version History with not more than 500 characters for this policy with details ${details}.`;
                                        const socket = sockets["version"];
                                        onGenerateWithAI(socket, prompt);
                                        return true;
                                    }}
                                />
                            </Form.Item>
                        </div>
                        <div>
                            <Form.Item label="Definitions (Optional)" name="definition">
                                <CustomTextAreaWithButton
                                    placeholder={`Define crucial terms used in the policy for clarity (e.g., "'Sensitive Data' refers to any information that could identify an individual, including names and addresses").`}
                                    uniqueKey="definition"
                                    loadingStates={loadingStates}
                                    setLoadingStates={setLoadingStates}
                                    value={form.getFieldValue('definition')} // Bind value to form field
                                    onChange={(e) => form.setFieldValue('definition', e.target.value)} // Update form value on change
                                    onClickButton={() => {
                                        form.setFieldValue('definition', '')
                                        const details = `
                                            Objective: ${pageOneValues['objective']}
                                            \nScope: ${pageOneValues['scope']}
                                            \nPolicy Statement: ${pageOneValues['statement']}
                                            \nResponsibilities: ${pageOneValues['responsibilities']}
                                            \nCompliance: ${pageOneValues['compliance']}
                                            \nReporting Violations: ${pageOneValues['violations']}
                                            \nExceptions: ${pageOneValues['exceptions']}
                                            \nApproval: ${pageOneValues['approval']}
                                        `;
                                        let prompt = `Generate a policy Definitions with not more than 500 characters for this policy with details ${details}.`;
                                        const socket = sockets["definition"];
                                        onGenerateWithAI(socket, prompt);
                                        return true;
                                    }}
                                />
                            </Form.Item>
                        </div>
                    </div>

                    <div className="flex flex-row justify-between">
                        <div>
                            <button
                                onClick={() => setIsPageOne(true)}
                                className="w-full md:w-[8rem] bg-transparent border border-customOrange text-customOrange rounded p-2"
                            >
                                Prev
                            </button>
                        </div>
                        <div>
                            <Form.Item>
                                <button
                                    type="submit"
                                    className="w-full md:w-[8rem] bg-customOrange text-white rounded p-2"
                                >
                                    Generate
                                </button>
                            </Form.Item>
                        </div>
                    </div>
                </>
            )}
        </Form>
    );
};

export default PolicyWriter;
