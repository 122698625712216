import { getUrlPath } from '@src/utils/app_functions';
import { Currency } from 'lucide-react';
import React, { createContext, useState, useEffect } from 'react';

export const ThemeContext = createContext({ savedTheme: 'light', toggleTheme: () => {}, isMobile: false, isLargeTab: false });

export const ThemeProvider = ({ children }) => {

    const [savedTheme, setSavedTheme] = useState(localStorage.getItem('theme') || 'light');
    const [isMobile, setIsMobile] = useState(false);

    const [isLargeTab, setIsLargeTab] = useState(false);

    const checkIfLargeTab = () => {
        // Check if the window's width is exactly 1024px
        setIsLargeTab(window.innerWidth === 1024);
    };

    useEffect(() => {
        // Perform the initial check on component mount
        checkIfLargeTab();

        // Add resize event listener to monitor width changes
        window.addEventListener('resize', checkIfLargeTab);

        // Clean up the event listener on component unmount
        return () => window.removeEventListener('resize', checkIfLargeTab);
    }, [window.innerWidth]);

  



    useEffect(() => {

        if (savedTheme === 'dark') {
            document.documentElement.classList.add('dark');
        } else {
            document.documentElement.classList.remove('dark');
        }
    }, [savedTheme]);

    useEffect(() => {
        // Function to check if the screen width is tablet or mobile size
        const checkScreenSize = () => {
            const isTabletOrMobile = window.innerWidth <= 1024;
            setIsMobile(isTabletOrMobile);
            console.log('isMobile',isTabletOrMobile)
        };

        // Create a ResizeObserver instance to monitor changes in the body element
        const resizeObserver = new ResizeObserver(() => {
            checkScreenSize();
        });

        // Observe the document body
        resizeObserver.observe(document.body);

        // Initial check on component mount
        checkScreenSize();

        // Cleanup observer on component unmount
        return () => resizeObserver.disconnect();
    }, [window.innerWidth]);

  


    const toggleTheme = () => {
        const newTheme = savedTheme === 'light' ? 'dark' : 'light';
        setSavedTheme(newTheme);
        localStorage.setItem('theme', newTheme);
    };

    return (
        <ThemeContext.Provider value={{ savedTheme, toggleTheme,isMobile,isLargeTab }}>
            {children}
        </ThemeContext.Provider>
    );
};
