/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import back from '@assets/chat/back_circle.svg';
import Arrow from '@src/assets/arrow';
import { GiShipWheel } from 'react-icons/gi';
import { UserLists } from './UserLists';
import cloudCheck from '@icons/cloud.png';
import { getRegion } from '@src/utils/app_functions';

type ConditionType<T> = {
    translationLoading: T;
    humanizing: T;
};
export const SubNavBar = ({
    title = 'Title',
    selected,
    onBack,
    humanized = false,
    className,
    onHumanizeClick,
    showActions,
    loading = false,
    canHumanize,
    canTranslate,
}: {
    title: string;
    selected: (v: string) => void;
    onBack?: () => void;
    humanized?: boolean;
    onHumanizeClick?: () => void;
    className?: string;
    showActions?: boolean;
    loading: boolean;
    canHumanize: boolean;
    canTranslate: boolean;
}) => {
    const [conditions, setConditions] = useState<ConditionType<boolean>>({
        translationLoading: false,
        humanizing: false,
    });

    const nigerianOptions = ['English', 'Yoruba', 'Igbo', 'Hausa', 'French', 'Spanish', 'Arabic'];
    const otherNationOptions = ['English', 'French', 'Spanish', 'Arabic' ]

    const [ languageOptions, setLanguageOptions] = useState<string[]>(['English']);

    useEffect(() => {
        getRegion().then((data) => {
            if ( data.location == 'ngn' ){
                setLanguageOptions(nigerianOptions);
            }else{
                setLanguageOptions(otherNationOptions);
            }
        });
    }, []);

    useEffect(() => {}, []);

    useEffect(() => {
        setConditions((c) => ({ ...c, translationLoading: !loading }));
    }, [loading]);

    useEffect(() => {
        setConditions((c) => ({ ...c, humanizing: !humanized }));
    }, [humanized]);

    function handleLanguage(language: string) {
        selected(language);
        setConditions((c) => ({ ...c, translationLoading: true }));
    }

    return (
        <section className={'h-14 px-4 flex justify-between sticky top-0 bg-white z-[300] items-center ' + className}>
            <div className="flex items-center gap-4 relative">
                <button onClick={onBack} type="button">
                    <img className="w-6" src={back} alt="back" />
                </button>
                <p className="font-medium text-[0.9rem]">Gmind Editor</p>
                <img className="w-[1.3rem] h-[1.3rem]" src={cloudCheck} alt="cloud check" />
            </div>
            {
                canTranslate && <div className="flex items-center gap-3 relative">
                    {showActions && (
                        <>
                            <label htmlFor="translate">Translate</label>
                            <Select
                                selected={(v) => {
                                    handleLanguage(v);
                                }}
                                options={['English', 'Yoruba', 'Igbo', 'Hausa', 'French', 'Spanish', 'Arabic']}
                            />
                            {conditions.translationLoading && (
                                <GiShipWheel className=" animate-spin absolute right-[-2rem] text-2xl" />
                            )}
                        </>
                    )}
                </div>
            }
            { 
                canHumanize && <div className="relative  flex items-center">
                    {showActions && (
                        <button
                            onClick={onHumanizeClick}
                            className="bg-brandOrange text-sm p-2 py-1 text-white font-medium rounded-md"
                            type="button"
                        >
                            <span>Humanize</span>
                        </button>
                    )}
                    {conditions.humanizing && <GiShipWheel className=" animate-spin absolute right-[-2rem] text-2xl" />}
                </div>
            }
            {
                // <div>{showActions ? <UserLists /> : <></>}</div>
            }
        </section>
    );
};

export const Select = ({ options, selected }: { options: string[]; selected: (v: string) => void }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState<string>(options[0] ?? '');

    const languagesAbbreviations: any = {
        English: 'en',
        Yoruba: 'yo',
        Igbo: 'ig',
        Hausa: 'ha',
        French: 'fr',
        Spanish: 'es',
        Arabic: 'ar',
    };

    return (
        <section
            role="button"
            onClick={() => {
                setIsOpen(!isOpen);
            }}
            className="border flex z-[300] items-center gap-10 justify-between border-gray-300 rounded-md p-1 relative h-fit w-[8rem]"
        >
            <p className="select-none">{selectedItem}</p>
            {isOpen && (
                <div className="absolute overflow-hidden left-0 right-0 bg-white shadow-md rounded-md translate-y-36 z-[10000]">
                    {options.map((option) => {
                        return (
                            <p
                                onClick={() => {
                                    setSelectedItem(option);
                                    selected(languagesAbbreviations[option]);
                                }}
                                className="select-none hover:bg-brandOrange text-sm p-2"
                            >
                                {option}
                            </p>
                        );
                    })}
                </div>
            )}
            <Arrow
                style={isOpen ? { transform: 'rotate(90deg)' } : {}}
                color="#e55109"
                className="w-4 transition-all -rotate-90"
            />
        </section>
    );
};
