import { getUrlPath } from '@src/utils/app_functions';
import { BASE_URL } from '@src/utils/constants';
import React, { createContext, useState, useContext, useMemo, useEffect } from 'react';

// Define interfaces for user details, document data, and responses
interface UserDetails {
    email: string;
    docId: string;
    role: string;
}

interface Collaborator {
    userId: string;
    email: string;
    acceptInvite: boolean;
    access: 'view' | 'edit';
    inviteExpiryDate: string;
    _id: string;
}

interface DocumentData {
    id: string;
    data: string;
    prompt: string;
    promptType: string;
    versions: string[];
    createdAt: string;
    updatedAt: string;
    collaborators: Collaborator[];
}

export interface DocumentResponse {
    statusCode: number;
    hasAccess: boolean;
    owner: boolean;
    role: string;
    message: string;
    data: DocumentData;
}

export interface ErrorDocumentResponse {
    statusCode: number;
    data: string;
}

export interface SaveDocumentInterface {
    promptSent: string;
    promptType: string;
    fromPage: string;
    content: string;
    doc_id: string;
}

export interface DocumentPropertiesJsonInterface {
    promptSent: string;
    promptType: string;
    fromPage: string;
    doc_id: string;
}

const DocumentContext = createContext<DocumentContextProps | undefined>(undefined);

export const DocumentProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [userDetails, setUserDetails] = useState<UserDetails | null>(null);
    const [error, setError] = useState<string | null>(null);
    const [fetchError, setFetchError] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [isRole, setIsRole] = useState<boolean>(false);
    const [document, setDocument] = useState<DocumentData | null>(null);
    const [documentResponse, setDocumentResponse] = useState<DocumentResponse | null>(null);
    const [currentPage, setCurrentPage] = useState<string | undefined>('Chat');
 




    const fetchPage = ()=>{
        const path = getUrlPath();
         
        if (path.pagename.includes('chat') ){
            setCurrentPage('Chat');
        }else if (path.pagename.includes('curriculum')){
            setCurrentPage('Curriculum Planner');
        }else if (path.pagename.includes('content')){
            setCurrentPage('Content Generator');
        }else if (path.pagename.includes('class')){
            setCurrentPage('Class Resources');
        }else if (path.pagename.includes('business')){
            setCurrentPage('Business Resources');
        }else if (path.pagename.includes('media')){
            setCurrentPage('Media Resources');
        }else if (path.pagename.includes('video')){ 
            setCurrentPage('Videos');
        }else {
            setCurrentPage(undefined)
        }

    }

    const fetchDocument = async (docId: string, canLoad = true) => {
        if (canLoad) {
            setLoading(true);
        }
        setError(null);

        const token = localStorage.getItem('token');

        try {
            const response = await fetch(`${BASE_URL}/document/get-document?documentId=${docId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': `Bearer ${token}`,
                },
            });

            if (!response.ok) {
                const errorMessage = `Error: ${response.status} ${response.statusText}`;
                const error: ErrorDocumentResponse = await response.json();

                if (error.statusCode === 401) {
                    await saveDocumentFromLocalStorage(docId);
                    return;
                }
                setError(errorMessage);
                throw new Error(errorMessage);
            }

            const data: DocumentResponse = await response.json();
            setDocument(data.data);
            setDocumentResponse(data);
            setFetchError(null); // Clear any previous errors
        } catch (error) {
            console.error('Error fetching document:', error);
            setError('Failed to fetch the document. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    const saveDocumentFromLocalStorage = async (docId: string) => {
        const documentContent = window.localStorage.getItem('essay_data');
        const documentProperties = window.localStorage.getItem('prompt_data');

        if (!documentContent || !documentProperties) {
            setError('Failed to fetch the document. Please try again.');
            return;
        }

        const documentPropertiesJson: DocumentPropertiesJsonInterface = JSON.parse(documentProperties);

        if (documentPropertiesJson.doc_id !== docId) {
            setError('Failed to fetch the document. Please try again.');
            return;
        }

        const dataToSend: SaveDocumentInterface = {
            promptSent: documentPropertiesJson.promptSent,
            promptType: documentPropertiesJson.promptType,
            content: documentContent,
            fromPage: documentPropertiesJson.fromPage,
            doc_id: docId,
        };

        setLoading(true);
        setError(null);

        const token = localStorage.getItem('token');

        try {
            const response = await fetch(`${BASE_URL}/document/save-document`, {
                method: 'POST',
                body: JSON.stringify(dataToSend),
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': `Bearer ${token}`,
                },
            });

            if (!response.ok) {
                const errorMessage = `Error: ${response.status} ${response.statusText}`;
                setError(errorMessage);
                throw new Error(errorMessage);
            }

            fetchDocument(docId, true);
        } catch (error) {
            console.error('Error fetching document:', error);
            setError('Failed to fetch the document. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    // Memoize the context value to prevent unnecessary re-renders
    const contextValue = useMemo(() => ({
        userDetails,
        error,
        document,
        loading,
        fetchDocument,
        documentResponse,
        isRole,
        fetchError,
        currentPage,
        fetchPage,
    }), [userDetails, error, document, loading, documentResponse, isRole, fetchError, currentPage, fetchPage]);

    return (
        <DocumentContext.Provider value={contextValue}>
            {children}
        </DocumentContext.Provider>
    );
};

// Hook to use the DocumentContext
export const useDocumentContext = () => {
    const context = useContext(DocumentContext);
    if (context === undefined) {
        throw new Error('useDocumentContext must be used within a DocumentProvider');
    }
    return context;
};
