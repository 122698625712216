// import { formatJSON } from '@/app/components/slidesForm/Step3';
// import useLocalStorage from '@/app/hooks/useLocalStorage';
// import { Slide } from '@/app/providers/store';
// import { BASE_URL, KEY } from '@/app/utils/constants';
// import { IUserData } from '@/app/utils/interfaces';
import { Slide } from '@src/providers/store';
import { BASE_URL } from '@src/utils/constants';
import axios from 'axios';
const token = localStorage.getItem('token');
const rule = `
Generate an image that exactly matches the description of the image provided by the user
Ensure that image is an exact match of the image description as provided by the user
I repeat ensure the image is an exact match of the image description as provided by the user
Follow the description provided by the user, and ensure it meets all their requirements.
Never add a text to the image generated. I repeat in no circumstance should you add a text to the image generated.

Kindly follow to the following rules:
 - no-caption or image-only
 - no-text-overlay
 - no-image-text just plain-image
`;


const imageRule = `
    Please generate an image following these rules:
    1. If the image is supposed to be realistic, make it as photorealistic as possible.
    2. If the image includes a human face, make the face very realistic with accurate features.
    3. Any text included in the image must have correct and accurate spelling.
    4. Take Note of the type of image you are asked to generate strictly, be it realistic, abstract,anime or avatar and make sure all generated images it into specified size
`;

export function formatJSON(jsonString: any) {
    try {
        const jsonObject = JSON.parse(jsonString);
        return JSON.stringify(jsonObject, null, 2);
    } catch (error) {
        console.error('Invalid JSON format:', error);
        return null;
    }
}

type Chats = { role: string; content: string };

let chats: Chats = { role: '', content: '' };
const isValidSlide = (slide: any): slide is Slide => {
    return (
        typeof slide === 'object' &&
        typeof slide.title === 'string' &&
        typeof slide.content === 'string' &&
        typeof slide.image === 'string'
    );
};
export const handleAutoGenerate = async (number: number, description: string): Promise<Slide[]> => {
    const sytemPrompt =
        `You are an AI-powered slide creation assistant designed to help users create engaging, informative, and visually appealing slides for various purposes, such as presentations, lectures, or educational content. Your goal is to generate slide content based on the user's input, ensuring that the slides are well-structured, concise, and effectively convey the intended message.

When a user provides you with the necessary information, your task is to:

1. Understand the user's requirements:
   - Ask for a clear and concise description of the topic or purpose of the slides.
   - Inquire about the target audience, their background knowledge, and the desired level of depth or complexity.
   - Determine the desired number of slides to be created.
   - Ask for any specific guidelines, branding requirements, or visual preferences the user may have.

2. Break down the topic into key points and subtopics:
   - Identify the main ideas, concepts, or messages that need to be conveyed through the slides.
   - Organize the content into a logical flow, ensuring a clear progression of ideas from one slide to another.
   - Determine the appropriate level of detail and depth for each slide based on the target audience and the overall purpose.

3. Create an outline for the slide deck:
   - Develop a structured outline that includes the main sections, subsections, and key points for each slide.
   - Ensure that the outline follows a coherent narrative and supports the overall objectives of the presentation.

4. Generate content for each slide:
   - Use the outline as a guide to create concise and engaging content for each slide.
   - Focus on one main idea or message per slide, using bullet points, short paragraphs, or visual aids to convey the information effectively.
   - Provide examples, analogies, or real-world applications to illustrate complex concepts and make the content more relatable to the audience.
   - Incorporate relevant data, statistics, or quotes to support the main points and add credibility to the presentation.

5. Suggest appropriate visual elements:
   - Recommend relevant images, diagrams, charts, or graphs that can enhance the understanding and visual appeal of each slide.
   - Ensure that the suggested visual elements are high-quality, properly labeled, and aligned with the content of the slide.
   - Provide guidance on the placement and sizing of visual elements to maintain a balanced and professional layout.

6. Apply effective design principles:
   - Use a consistent color scheme, font style, and formatting throughout the slide deck to maintain a cohesive and professional appearance.
   - Ensure that the text is legible, with appropriate font sizes and color contrast for easy readability.
   - Recommend appropriate slide transitions, animations, or interactive elements that enhance the flow and engagement of the presentation.

7. Review and refine the generated slides:
   - Proofread the content for clarity, accuracy, and consistency, making necessary revisions or improvements.
   - Check for any spelling, grammar, or punctuation errors and ensure that the language is concise and easy to understand.
   - Verify that the slides align with the user's requirements, branding guidelines, and overall objectives.

8. Provide the generated slides in the requested format:
   - Organize the slide content in a JSON array format, where each element represents a single slide.
   - Include the slide title, content (text and visual elements), and any relevant notes or annotations.
   - Ensure that the output is well-structured, properly formatted, and ready for the user to import or integrate into their preferred presentation software.

9. Offer additional support and recommendations:
   - Provide tips and best practices for delivering an effective presentation based on the generated slides.
   - Suggest ways to engage the audience, handle questions, and make the presentation more interactive and memorable.
   - Offer to make further revisions or adjustments based on the user's feedback or changing requirements.

Remember, your ultimate goal is to create high-quality, engaging, and informative slides that effectively communicate the user's message to their target audience. Strive to generate content that is clear, concise, visually appealing, and aligned with the user's objectives. Engage in a dialogue with the user to ensure that the generated slides meet their specific needs and expectations.`;
    const prompt = `Greetings AI! We require your expertise to create content for a presentation on "${description}" across ${number} slides. Each slide must follow this format within its respective field.
     
    Format:
        Objective: Educate learners about [Key Concepts] and [Learning Objectives]
        Slide Structure:
        Slide 1: Introduction to [Topic]
        Slide 2-3: Definition and explanation of [Key Concept 1]
        Slide 4-5: Analysis of [Key Concept 2]
        Slide 6-7: Evaluation of [Key Concept 3]
        Slide 8: Conclusion and summary
        Content Requirements:
        Clear and concise language
        Visual elements (images, charts, graphs) to illustrate key concepts
        Recent data and statistics to support points
        Innovative solutions and best practices
        Examples and case studies
        * Tone and Style:
        * Informative
        * Engaging
        * Objective
        * Clear and concise
        Format:
        * Each slide should have a clear heading and concise bullet points
        * Use clear and concise language, avoiding jargon and technical terms
        * make the content engaging and 
        * Ensure content is accurate, up-to-date, and supported by credible sources informative content that aligns with pedagogical principles and deep research on the topical issue
     
     Please provide the content in a JSON array structure, where each object contains a "title", "content","image"--(this would be empty string) property for seamless integration into our presentation. for further understanding, this is the inteface for each slide: export interface Slide {
        title: string;
        content: string;
        image: string;
      }
     Your contribution will significantly enhance the clarity and depth of our presentation.
     Please let your result be the json array only because i am directly caaling a .map function to display your results contents, 
     for example you usually make a response tat starts like this: "Sure, I understand your requirement. Here's the JSON array:" and this makes .map fail!.
     Also note:Do not put quotation marks or escape character in the output fields Thank you for your valuable input!`;

    try {
        let msgs = chats;
        msgs = { role: 'user', content: prompt };
        const response = await axios.post(
            `${BASE_URL}/knowledge-base`,
            {
                model: 'gpt-4',
                messages: [
                    {
                        role: 'system',
                        content: sytemPrompt,
                    },
                    msgs,
                ],
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            },
        );
        msgs = response.data.data.choices[0].message;
        const AIRESULT = response?.data?.data?.choices[0]?.message;
        const generatedContent = AIRESULT?.content;
        // const formattedData = formatJSON(generatedContent);
        const formattedData = JSON.parse(generatedContent) as Slide[];
        if (!Array.isArray(formattedData) || !formattedData.every(isValidSlide)) {
            throw new Error('Invalid slide data format');
        }
        if (!formattedData) {
            return [
                {
                    title: '',
                    content: '',
                    image: '',
                },
            ];
        }
        return formattedData;
    } catch (error: any) {
        return [];
    } finally {
    }
};
export function generateSonObjects(num: number): Slide[] {
    const sonObjects: Slide[] = [];

    for (let i = 0; i < num; i++) {
        const sonObject: Slide = {
            title: `Slide Title ${i + 1}`,
            content: 'content goes here..',
            image: '',
        };
        sonObjects.push(sonObject);
    }

    return sonObjects;
}

export const generateOpenAimage = async (prompt: any, size: string, slide = false ) => {
    const baseurl = BASE_URL;
    try {
        console.log(size);
        const imageSize = size ?? '1024x1024';

        const response = await axios.post(
            `${baseurl}/gmind/image_maker`,
            {
                prompt: `${prompt}-Take note of these rules: ${ slide ? rule: imageRule }`,
                size: imageSize,
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            },
        );
        console.log(response);
        return response;
    } catch (error) {
        console.error('Error generating image:', error);
        // throw new Error("Failed to generate image");
    }
};

export const generateImagesList = async (prompt: any, size: string) => {
    try {
        const response = await generateOpenAimage(prompt, size);
        if (response?.data) {
            return response.data.data[0];
        }
        throw new Error('No image data received');
    } catch (error) {
        console.error('Error generating images:', error);
        throw new Error('Failed to generate images');
    }
};